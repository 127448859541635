<div class="modal general noselect">
  <div class="modal-content" #content>
    <div class="header clearfix m-vb-3 text-center">
      <h3 class="m-vt-1 m-vb-1">{{ title }}</h3>
      <button class="close-btn btn-t-b b-transparent rounded" (click)="onClose()">
        <app-svg-icon
          path="M155 72l-73 73l367 367l-367 367l73 73l367 -367l367 367l73 -73l-367 -367l367 -367l-73 -73l-367 367z"
          viewBox="-10 0 1044 1024"
        ></app-svg-icon>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</div>
