<app-header-main [core]="core"></app-header-main>

<div class="icons-overview text-center">
    <!-- content -->
    <div
        class="content"
        (drop)="onContentDrop($event)"
        (dragover)="onContentDragOver($event)"
        (dragleave)="onContentDragLeave()"
        #content
    >
        <!-- header -->
        <app-header [core]="core"></app-header>

        <!-- TODO: no-search-results -->
        <!-- content -->
        <div *ngIf="core.icons.length === 0" class="no-icons noselect">
            <div class="no-icons-inner">
                <img src="assets/images/no-icons.png" class="pointer-events-none">
                <h2 class="m-vt-0" i18n="@@content.empty.title">Import Icons</h2>
                <label class="file-upload-input">
                    <input
                        type="file"
                        (change)="onFileInput($event)"
                        accept="image/svg+xml, application/json, font/ttf, application/vnd.ms-fontobject, .ttf, .eot, .svg"
                        multiple>
                    <app-svg-icon
                        path="M522 8l-29 28l-285 284l59 59l214 -215v673h82v-673l214 215l59 -59l-285 -284zM156 918v82h732v-82h-732z"
                        viewBox="-10 0 1044 1024"
                    ></app-svg-icon>
                    <div i18n="@@header.upload">Import</div>
                </label>
            </div>
        </div>
        <div *ngIf="core.icons.length > 0" class="grid">
            <ag-virtual-scroll [items]="core.iconRows" [min-row-height]="155" height="100%" #vs>
                <div *ngFor="let rowIcons of vs.items" class="icon-row-grid"
                     [style]="{'grid-template-columns': 'repeat(' + core.iconColumnsCount + ', 1fr)'}">
                    <app-icon-element *ngFor="let icon of rowIcons" [icon]="icon" [core]="core"></app-icon-element>
                </div>
            </ag-virtual-scroll>
        </div>

        <!-- dragover modal -->
        <div class="modal dragover pointer-events-none" *ngIf="dragover"></div>
    </div>

    <!-- notifications -->
    <app-notifications></app-notifications>

    <!-- sidebar (overview/single/multiple) -->
    <app-sidebar-overview *ngIf="core.selectedIcons.length === 0" [core]="core"></app-sidebar-overview>
    <app-sidebar-single *ngIf="core.selectedIcons.length === 1" [core]="core"></app-sidebar-single>
    <app-sidebar-multiple *ngIf="core.selectedIcons.length > 1" [core]="core"></app-sidebar-multiple>
</div>

<!-- loading modal -->
<div class="modal general loading" *ngIf="core.loading">
    <div class="modal-content" i18n="@@loading">Loading...</div>
</div>

<app-modal-delta [core]="core"></app-modal-delta>
<app-modal-api-documentation [core]="core"></app-modal-api-documentation>
<app-modal-property [core]="core"></app-modal-property>
<app-modal-login [core]="core"></app-modal-login>
<app-modal-user [core]="core"></app-modal-user>
<app-modal-purchase [core]="core"></app-modal-purchase>
<app-modal-login-expired [core]="core"></app-modal-login-expired>

<app-prompt></app-prompt>
