import { environment } from '../../../environments/environment'

export class Util {
    public static unicodeToNumber(unicode: string) {
        return parseInt(unicode, 16)
    }

    public static numberToUnicode(number: number) {
        return number.toString(16)
    }

    // TODO: move to core util
    public static sanitizeName(name: string) {
        // TODO: if not ascii
        return name.replace(/\$/g, 'S')
    }

    public static parseIconFileName(fileName: string) {
        let code = ''
        let name

        // get code/name from encoded-file-name with format ue{code}-{name}.svg
        if (fileName.startsWith('ue') === true) {
            const parts = fileName.split('-')

            code = parts[0] // TODO: should we parseInt(), because of the "ue" prefix
            name = parts.slice(1).join('-').replace('.svg', '')
        } else {
            name = fileName.replace('.svg', '')
            // TODO: generate unicode by incrementing the last one if none is given?
        }

        return {
            name: name,
            code: code
        }
    }

    public static unique(array: string[]) {
        return [...new Set(array)]
    }

    public static intersect(array1: string[], array2: string[]) {
        return array1.filter(value => array2.includes(value))
    }

    public static diff(array1: string[], array2: string[]) {
        return array1.filter(x => !array2.includes(x))
    }

    public static removeElements(array: string[], subarray: string[]) {
        const map = new Set(subarray)
        return array.filter(name => {
            return !map.has(name)
        })
    }

    public static clone(object: unknown) {
        return JSON.parse(JSON.stringify(object))
    }

    public static areArraysEqual(a: string[], b: string[]) {
        return (
            a.length == b.length &&
            a.every((element, index) => {
                return element === b[index]
            })
        )
    }

    public static randomHash() {
        return btoa(Math.random().toString()).replace(/=/g, '').toLowerCase()
    }

    public static buildApiEndpointCode(options: { name: string; jsonData: string }) {
        const jsonData = options.jsonData

        return [
            `curl -i -X POST ${environment.baseUrl}/${options.name}`,
            "-H 'Content-Type: application/json'",
            `-d '${jsonData}'`
        ].join(' \\\n')
    }
}
