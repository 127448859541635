<!-- TODO: i18n -->
<app-modal *ngIf="core.delta" title="Unterschiede" (close)="onClose()">
  <div class="subheader m-vt-1 m-vb-1">
    <!-- TODO: i18n -->
    <div><h3>Code</h3></div>
    <div><h3>Name</h3></div>
    <div><h3>Meta</h3></div>
  </div>
  <div class="content">
    <div>
      <!-- TODO: i18n -->
      <div *ngFor="let codeDiff of core.delta.codes | keyvalue" class="text-left m-vb-1">
        {{ codeDiff.key }} -> {{ codeDiff.value || "(neu)" }}
      </div>
    </div>
    <div>
      <!-- TODO: i18n -->
      <div *ngFor="let nameDiff of core.delta.names | keyvalue" class="text-left m-vb-1">
        {{ nameDiff.key }} -> {{ nameDiff.value || "(neu)" }}
      </div>
    </div>
    <div>
      <!-- TODO: i18n -->
      <div *ngFor="let metaDiff of core.delta.meta | keyvalue" class="text-left m-vb-1">
        {{ metaDiff.key }} -> (New Data)
      </div>
    </div>
  </div>
  <div class="footer m-vt-1">
    <!-- TODO: i18n -->
    <div><button (click)="onApplyCodes($event, core.delta.codes)">Codes Übernehmen</button></div>
    <div><button (click)="onApplyNames($event, core.delta.names)">Namen Übernehmen</button></div>
    <div><button (click)="onApplyMeta($event, core.delta.meta)">Metadaten Übernehmen</button></div>
  </div>
</app-modal>
