import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { IconsUI } from '../core/icons-ui'
import { Properties } from '../core/properties'

@Component({
    selector: 'app-modal-property',
    templateUrl: './modal-property.component.html',
    styleUrls: ['./modal-property.component.scss']
})
export class ModalPropertyComponent {
    @Input() core: IconsUI = new IconsUI()
    @ViewChild('nameInput') nameInputRef?: ElementRef<HTMLInputElement>
    @ViewChild('valueTextarea') valueTextareaRef?: ElementRef<HTMLTextAreaElement>

    close() {
        this.core.selectedProperty = undefined
    }

    // --

    async onSave() {
        const value = this.valueTextareaRef?.nativeElement.value.trim() ?? ''
        let name = this.nameInputRef?.nativeElement.value.trim()

        if (name) {
            name = Properties.stripName(name)
        }

        if (name && this.core.selectedProperty) {
            // rename property only if "name" has changed
            if (this.core.selectedProperty.name !== name) {
                this.core.renameProperty(this.core.selectedProperty.path, name)
            }

            this.core.selectedProperty.value = value

            await this.core.saveDebounced()

            this.close()
        }
    }

    onClose() {
        this.close()
    }
}
