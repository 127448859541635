<div *ngIf="prompt.data" class="modal general noselect" (click)="onBackgroundClick($event)">
  <div class="modal-content text-center" #content>
    <h2>{{ prompt.data.title }}</h2>
    <div class="m-vb-3 line-height-paragraph">{{ prompt.data.text }}</div>
    <div>
      <button class="m-hr-2" (click)="onConfirm()">{{ prompt.data.confirmLabel ?? confirmLabel }}</button>
      <button (click)="onCancel()">{{ prompt.data.cancelLabel ?? cancelLabel }}</button>
    </div>
  </div>
</div>
