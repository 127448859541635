import { Component } from '@angular/core'
import { Notification } from '../core/notification'

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
    notification = Notification

    // TODO: use another position for notifications container if a modal is open
}
