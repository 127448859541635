<div class="icons-overview-header noselect">
    <div class="header-logo">
        <img src="assets/images/logo.png">
    </div>
    <div class="header-name"><h3 class="semi-bold">Fontgenerator</h3></div>
    <div class="header-flex"></div>
    <div class="header-search">
        <div class="search-input-wrapper">
            <input type="text" class="search-input input-b" (input)="onSearchInput($event)">
            <app-svg-icon
                path="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                viewBox="0 0 24 24"
            ></app-svg-icon>
        </div>
    </div>
    <div class="header-flex"></div>
    <div *ngIf="!core.session">
        <button class="btn-c" (click)="onOpenLoginModal()">
            <app-svg-icon
                path="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z"
                viewBox="0 0 24 24"
            ></app-svg-icon>
            <div i18n="@@header.login">Login</div>
        </button>
    </div>
    <div class="cursor-pointer">
        <button class="rounded btn-t-b b-transparent" (click)="onHelp()">
            <app-svg-icon
                path="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z"
                viewBox="0 0 24 24"
            ></app-svg-icon>
        </button>
    </div>
    <div class="cursor-pointer">
        <app-dropdown
            icon="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
            iconViewBox="0 0 24 24"
            [sections]="menuOptions"
            (beforeShow)="onBeforeShowMenuDropdown()"
        ></app-dropdown>
    </div>
</div>
