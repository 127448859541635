import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { IconOverviewComponent } from './icon-overview/icon-overview.component'
import { IconTrainingComponent } from './icon-training/icon-training.component'
import { AiComponent } from './ai/ai.component'

const routes: Routes = [
    { path: 'ai', component: AiComponent },
//    { path: 'training', component: IconTrainingComponent },
    { path: '**', component: IconOverviewComponent }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
