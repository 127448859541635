import { Component, Input } from '@angular/core'
import { IconsUI } from '../core/icons-ui'

@Component({
    selector: 'app-modal-login-expired',
    templateUrl: './modal-login-expired.component.html',
    styleUrls: ['./modal-login-expired.component.scss']
})
export class ModalLoginExpiredComponent {
    @Input() core: IconsUI = new IconsUI()

    onLogin() {
        this.core.loginExpiredModal = false
        this.core.loginModal = true
    }

    onClose() {
        this.core.loginExpiredModal = false
    }
}
