import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { IconsUI } from '../core/icons-ui'
import { Icon } from '../core/icons'
import { IconsAPI } from '../core/icons-api'
import { IconsStorage } from '../core/storage/icons-storage'
import Selecto from 'selecto'

@Component({
    selector: 'app-icon-overview',
    templateUrl: './icon-overview.component.html',
    styleUrls: ['./icon-overview.component.scss']
})
export class IconOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('content') contentRef?: ElementRef<HTMLElement>

    core: IconsUI
    dragover = false
    selecto?: Selecto

    constructor(private http: HttpClient) {
        const api = new IconsAPI(this.http)
        this.core = IconsUI.initInstance(api)
    }

    async ngOnInit() {
        this.core.setIcons(await IconsStorage.getIcons())
        this.core.sortIcons()
        this.core.updateProperties() // this will "repair" all existing properties

        this.core.session = await IconsStorage.getSession()

        // setTimeout(async () => {
        //   await this.core.refreshFromServer()
        // })
    }

    ngAfterViewInit(): void {
        if (this.contentRef) {
            this.initMouseSelection(this.contentRef.nativeElement)
        }

        window.addEventListener('keydown', this.onKeyDown.bind(this))
    }

    ngOnDestroy() {
        window.removeEventListener('keydown', this.onKeyDown.bind(this))
    }

    initMouseSelection(container: HTMLElement) {
        const selecto = new Selecto({
            container: container,
            selectableTargets: ['.icons-overview__icon'],
            hitRate: 0,
            toggleContinueSelect: 'meta' // by pressing this key, the selection will be extended
        })

        selecto.on('select', (e: any) => {
            e.added.forEach((el: HTMLElement) => {
                el.classList.add('selected')
            })
            e.removed.forEach((el: HTMLElement) => {
                el.classList.remove('selected')
            })
        })

        selecto.on('selectEnd', (e: any) => {
            const selectedIcons = []

            for (const iconElement of e.selected) {
                const index = iconElement.getAttribute('data-index')
                const icon = this.core.icons[index]

                selectedIcons.push(icon)
            }

            this.core.infoMode = false
            this.core.selectedIcons = selectedIcons
            this.core.updateSelectedIconsDelta()
            this.core.updatePropertySchema()
        })

        this.selecto?.destroy()
        this.selecto = selecto
    }

    selectAll() {
        const visibleIconElements = document.querySelectorAll('.icons-overview__icon')
        const iconElements: HTMLElement[] = []
        const icons: Icon[] = []

        visibleIconElements.forEach(iconElement => {
            const dataIndex = iconElement.getAttribute('data-index')

            if (dataIndex) {
                const icon = this.core.icons[parseInt(dataIndex)]

                if (icon) {
                    icons.push(icon)
                }
            }

            iconElements.push(iconElement as HTMLElement)
        })

        // update selection in "selecto" instance
        this.selecto?.setSelectedTargets(iconElements)

        this.core.infoMode = false
        this.core.selectedIcons = icons
        this.core.updateSelectedIconsDelta()
        this.core.updatePropertySchema()
    }

    // -- handler

    onContentDragOver(event: Event) {
        event.preventDefault()
        event.stopPropagation()

        this.dragover = true
    }

    onContentDragLeave() {
        this.dragover = false
    }

    async onContentDrop(event: DragEvent) {
        event.preventDefault()
        event.stopPropagation()

        if (event.dataTransfer) {
            this.dragover = false
            await this.core.processFiles(event.dataTransfer.files)
        }
    }

    async onFileInput(event: Event) {
        if (event.target instanceof HTMLInputElement && event.target.files) {
            await this.core.processFiles(event.target.files)
        }
    }

    async onKeyDown(event: KeyboardEvent) {
        if (!event.key) {
            return
        }

        // if text input is focused do nothing
        if (document.activeElement) {
            const tagName = document.activeElement.tagName.toLowerCase()
            if (['input', 'textarea'].includes(tagName)) {
                return
            }
        }

        // if modal is open do nothing
        if (this.core.userModal || this.core.loginModal || this.core.apiModal || this.core.delta) {
            return
        }

        const key = event.key.toLowerCase()

        if (key === 'backspace' || key === 'delete') {
            event.preventDefault()
            await this.core.deleteIconsWithConfirm(this.core.selectedIcons)
        } else if (event.metaKey) {
            if (key === 'a') {
                event.preventDefault()
                this.selectAll()
            }
            // else if(key === 'c') {
            //   event.preventDefault()
            //
            //   const icons: IconClipboard[] = []
            //   for(const icon of this.core.selectedIcons) {
            //     icons.push({
            //       name: icon.name, // TODO: name with suffix?
            //       tags: icon.tags,
            //       content: icon.content,
            //     })
            //   }
            //
            //   Clipboard.copyWithNotification(JSON.stringify(icons))
            // }
            // else if(key === 'v') {
            //   event.preventDefault()
            //
            //   const text = await navigator.clipboard.readText();
            //   const icons: IconClipboard[] = JSON.parse(text)
            //
            //   for(const icon of icons) {
            //     this.core.addIcon({
            //       ...icon,
            //       file: BlobUtil.svgToBlob(icon.content),
            //       code: "", // TODO: generate next available code?
            //       glyph: "",
            //       thumb: ""
            //     })
            //   }
            //
            //   this.core.saveDebounced()
            // }
        }
    }

    @HostListener('window:resize')
    onResize() {
        // TODO: debounce
        this.core.iconColumnsCount = this.core.determineIconColumnsCount()
        this.core.updateIconRows()
    }

    @HostListener('window:online')
    async onOnline() {
        console.log('is online')
        // TODO: only one request in a certain time interval ?
        await this.core.refreshToken()
    }
}
