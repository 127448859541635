import { Component, Input } from '@angular/core'
import { Properties, PropertySchemaElement } from '../../core/properties'
import { Util } from '../../core/util/util'
import { IconsUI } from '../../core/icons-ui'

@Component({
    selector: 'app-sidebar-overview-element',
    templateUrl: './sidebar-overview-element.component.html',
    styleUrls: ['./sidebar-overview-element.component.scss']
})
export class SidebarOverviewElementComponent {
    @Input() core: IconsUI = new IconsUI()
    @Input() element: PropertySchemaElement = { name: '', path: '', values: [] }
    @Input() parent?: PropertySchemaElement
    collapsed = false
    dragged = false
    dragover = false

    // TODO: resolve this helper function
    computeUnique(array: string[]) {
        return Util.unique(array)
    }

    onToggleCollapsable() {
        this.collapsed = !this.collapsed
    }

    onLabelClick(element: PropertySchemaElement) {
        if (element.values.length > 0) {
            const properties: Record<string, string[]> = {}
            const filteredValues = this.core.filter.properties[element.path]

            // always set filter and reset only if values differ (-> another element)
            if (!filteredValues || !Util.areArraysEqual(filteredValues, element.values)) {
                properties[element.path] = element.values.slice(0) // create a copy to prevent reference errors
            }

            // toggle behavior: if an empty properties map is passed, the filter will be reset
            this.core.filterIcons({
                properties: properties
            })
        }
    }

    onValueClick(element: PropertySchemaElement, value: string) {
        const properties: Record<string, string[]> = {}
        const filteredValues = this.core.filter.properties[element.path]

        // property is not filtered, so we will add it
        if (!filteredValues || !filteredValues.includes(value) || filteredValues.length > 1) {
            properties[element.path] = [value]
        }

        // toggle behavior: if an empty properties map is passed, the filter will be reset
        this.core.filterIcons({
            properties: properties
        })
    }

    onDragStart(event: DragEvent) {
        event.stopPropagation()
        event.stopImmediatePropagation()
        this.dragged = true
        this.core.draggedSchemaElement = this.element
        this.core.draggedSchemaElementParent = this.parent
    }

    onDragOver(event: DragEvent) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
        if (!this.dragged) {
            this.dragover = true
        }
    }

    onDragLeave() {
        this.dragover = false
    }

    onDragEnd() {
        this.dragged = false
    }

    async onDrop(event: DragEvent) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        this.dragover = false

        const from = this.core.draggedSchemaElement
        const fromParent = this.core.draggedSchemaElementParent

        // remove from old location
        if (from) {
            const pathNew = Properties.buildPath(this.element.path, from.name)
            console.log(from.path, '->', pathNew)

            // target path is not allowed to be a substring of the element path -> descendant or same element
            if (pathNew.startsWith(from.path)) {
                console.log('is descendant', from, '->', this.element)
                return
            }

            // move property first, if something went wrong an error will be thrown
            this.core.moveProperty(from.path, pathNew)

            // remove from parent
            if (fromParent) {
                delete fromParent.properties?.[from.name]
            }
            // remove from root
            else {
                delete this.core.propertySchema[from.name]
            }

            if (!this.element.properties) {
                this.element.properties = {}
            }
            this.element.properties[from.name] = from

            await this.core.saveDebounced()

            from.path = pathNew
        }

        delete this.core.draggedSchemaElement
        delete this.core.draggedSchemaElementParent
    }
}
