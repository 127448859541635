interface NotificationData {
    text: string
    type: 'general' | 'error'
}

export class Notification {
    public static notifications: NotificationData[] = []

    public static show(text: string) {
        Notification.notifications.push({
            text: text,
            type: 'general'
        })
        Notification.removeLast()
    }

    public static showError(text: string = $localize`:@@error.default:Something went wrong`) {
        Notification.notifications.push({
            text: text,
            type: 'error'
        })
        Notification.removeLast()
    }

    public static removeLast() {
        setTimeout(() => {
            Notification.notifications.splice(0, 1)
        }, 3000)
    }
}
