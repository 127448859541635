import { Icon, IconCustomProperty } from './icons'
import * as deepmerge from 'deepmerge'

export interface PropertySchemaElement {
    name: string
    path: string
    values: string[]
    properties?: PropertySchema
}

export type PropertySchema = Record<string, PropertySchemaElement>

export class Properties {
    public static getSchema(icons: Icon[]) {
        let schema: PropertySchema = {}

        for (const icon of icons) {
            if (icon.properties) {
                schema = deepmerge(schema, Properties.getPropertySchema(icon.properties))
            }
        }
        return schema
    }

    public static getPropertySchema(properties: IconCustomProperty[], parentPath = '') {
        const schema: PropertySchema = {}

        for (const property of properties) {
            if (!schema[property.name]) {
                schema[property.name] = {
                    name: property.name,
                    path: '',
                    values: []
                }
            }

            schema[property.name].path = Properties.buildPath(parentPath, property.name)

            // TODO: temporary workaround for property "categories", which is an array
            if (Array.isArray(property.value)) {
                property.value = property.value.join(', ')
            }
            // ignore empty values
            if (property.value.trim() !== '') {
                schema[property.name].values.push(property.value)
            }

            if (property.properties) {
                schema[property.name].properties = Properties.getPropertySchema(
                    property.properties,
                    schema[property.name].path
                )
            }
        }
        return schema
    }

    // TODO: rename properly
    // TODO: code cleaning
    public static computeProperties(
        properties: IconCustomProperty[],
        propertiesSchema: PropertySchema,
        parentPath = ''
    ) {
        const propertiesNew = []

        for (const name in propertiesSchema) {
            let property

            for (const prop of properties) {
                if (prop.name === name) {
                    property = prop
                    break
                }
            }

            const subProperties = propertiesSchema[name].properties
            const path = Properties.buildPath(parentPath, name)
            // keep the property reference alive, so we don't need to rebuild the schema all the time and can use the reference to update values
            const p = property ? property : { name: name, value: '', path: '' }

            p.path = path
            p.properties = subProperties
                ? Properties.computeProperties((property || {}).properties ?? [], subProperties, path)
                : []

            propertiesNew.push(p)
        }
        return propertiesNew
    }

    public static renameProperty(
        properties: IconCustomProperty[],
        propertyPath: string,
        propertyName: string,
        propertyValue?: string
    ) {
        // name has been updated, so we must build a new path
        const parentPath = Properties.getParentPath(propertyPath)
        const pathNew = Properties.buildPath(parentPath, propertyName)

        console.log(`---> "${propertyPath}" -> "${pathNew}"`)

        // if the old and the new paths are the same, the name did not change, and therefore we do not need to check the target
        if (propertyPath !== pathNew) {
            // we can only set to a path that does not exist, otherwise we will throw an error
            const targetResult = Properties.findProperty(properties, pathNew)

            if (targetResult) {
                throw new Error('Target is not empty.')
            }
        }

        const result = Properties.findProperty(properties, propertyPath)

        if (result) {
            // rename property and set value
            result.property.name = propertyName

            if (typeof propertyValue !== 'undefined') {
                // TODO: won't be updated properly in autocomplete dropdown
                result.property.value = propertyValue
            }

            // name has been updated, so we must update the path too
            result.property.path = pathNew
        }
        return properties
    }

    public static moveProperty(properties: IconCustomProperty[], propertyPath: string, propertyPathNew: string) {
        // we can only set to a path that does not exist, otherwise we will throw an error
        const targetResult = Properties.findProperty(properties, propertyPathNew)
        if (targetResult) {
            throw new Error('Target is not empty.')
        }

        const result = Properties.findProperty(properties, propertyPath)

        // delete property at old path
        Properties.deleteProperty(properties, propertyPath)

        if (result) {
            // set property at new path and create path if needed
            Properties.setProperty(properties, propertyPathNew, result.property)
        }

        return properties
    }

    public static deleteProperty(properties: IconCustomProperty[], propertyPath: string) {
        const result = Properties.findProperty(properties, propertyPath)

        if (result) {
            // remove property from parent properties
            const index = result.properties.indexOf(result.property)
            result.properties.splice(index, 1)
        }
        return properties
    }

    // TODO: cache by path
    public static findProperty(properties: IconCustomProperty[], propertyPath: string) {
        // propertyPath example: property.test.xyz -> the index values and "properties" keys will be added automatically
        const parts = propertyPath.split('.')
        let lookupProperties = properties
        let i = 0
        let found

        do {
            found = false

            for (const property of lookupProperties) {
                if (property.name === parts[i]) {
                    i++
                    found = true

                    if (i === parts.length) {
                        return {
                            property: property,
                            properties: lookupProperties
                        }
                    }

                    lookupProperties = property.properties ?? []
                    break
                }
            }
        } while (i < parts.length && found === true)

        return null
    }

    public static findPropertySchema(properties: PropertySchema, propertyPath: string) {
        // propertyPath example: property.test.xyz -> the index values and "properties" keys will be added automatically
        const parts = propertyPath.split('.')
        let lookupProperties = properties
        let i = 0
        let found

        do {
            const propertySchema = lookupProperties[parts[i]]

            i++
            found = false

            if (propertySchema) {
                if (i === parts.length) {
                    return propertySchema
                }
                found = true
            }

            lookupProperties = propertySchema?.properties ?? {}
        } while (i < parts.length && found === true)

        return null
    }

    public static buildPath(parentPath: string, propertyName: string) {
        const parentPathTrimmed = parentPath.trim()

        if (parentPathTrimmed === '') {
            return propertyName
        }
        return `${parentPathTrimmed}.${propertyName}`
    }

    public static getParentPath(path: string) {
        const parts = path.split('.')
        parts.pop()

        return parts.join('.')
    }

    public static stripName(name: string) {
        // some characters are not allowed to be contained in the name (e.g. ".", which is used to separate path components)
        // keep only alphanumeric characters
        return name.trim().replace(/[\W_]+/g, '')
    }

    public static setProperty(properties: IconCustomProperty[], path: string, property: IconCustomProperty) {
        const pathParts = path.split('.')
        let tmp = properties
        let i = 0
        let tmpPath = ''

        // create missing parts
        for (; i < pathParts.length - 1; i++) {
            const name = pathParts[i]
            let found = false

            tmpPath = Properties.buildPath(tmpPath, name)

            for (const tmpProperty of tmp) {
                if (tmpProperty.name === name) {
                    found = true

                    if (!tmpProperty.properties) {
                        tmpProperty.properties = []
                    }
                    tmp = tmpProperty.properties
                    break
                }
            }

            if (!found) {
                const tmpProperty = {
                    name: name,
                    value: '',
                    path: tmpPath,
                    properties: []
                }
                // TODO: push new element
                tmp.push(tmpProperty)

                tmp = tmpProperty.properties
            }
        }

        tmp.push(property) // at the end of the chain add the value in

        property.path = path // we set the property at this path, so we must update it too
    }

    public static getUniqueName(name: string, properties: IconCustomProperty[]) {
        let duplicatesCount = 0
        const regex = new RegExp(`${name}( (\\d+))*`)

        for (const p of properties) {
            const result = regex.exec(p.name)

            if (result) {
                if (result[2]) {
                    const c = parseInt(result[2])

                    if (c >= duplicatesCount) {
                        duplicatesCount = c + 1
                    }
                } else {
                    duplicatesCount++
                }
            }
        }

        if (duplicatesCount > 0) {
            name = `${name} ${duplicatesCount}`
        }

        return name
    }

    public static repair(properties: IconCustomProperty[]) {
        for (const property of properties) {
            const parentPath = Properties.getParentPath(property.path)

            property.name = Properties.stripName(property.name)
            property.path = Properties.buildPath(parentPath, property.name)

            if (property.properties) {
                property.properties = Properties.repair(property.properties)
            }
        }
        return properties
    }
}
