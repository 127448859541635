import { Component, Input } from '@angular/core'
import { IconsUI } from '../core/icons-ui'
import { Mapping } from '../core/mapping'

@Component({
    selector: 'app-modal-delta',
    templateUrl: './modal-delta.component.html',
    styleUrls: ['./modal-delta.component.scss']
})
export class ModalDeltaComponent {
    @Input() core: IconsUI = new IconsUI()

    // --

    onClose() {
        delete this.core.delta
    }

    async onApplyNames(event: any, nameDiffs: Record<string, any>) {
        event.preventDefault()

        for (const icon of this.core.icons) {
            const nameNew = (nameDiffs[icon.name] || '').trim()

            if (nameNew.length > 0) {
                // console.log("[UPDATE] ", icon.code, icon.name, "|", nameDiffs[icon.name])
                icon.name = nameNew
            } else {
                console.log('[NOTHING]', icon.code, icon.name, '|', nameDiffs[icon.name])
            }
        }

        await this.core.saveDebounced()
        delete this.core.delta
    }

    onApplyCodes(event: any, codeDiffs: Record<string, any>) {
        event.preventDefault()
        console.log('apply codes')
    }

    async onApplyMeta(event: any, metaDiffs: Record<string, any>) {
        event.preventDefault()

        Mapping.applyMetaData(this.core.icons, metaDiffs)

        await this.core.saveDebounced()
        this.core.updateProperties()
        delete this.core.delta
    }
}
