import { Util } from './util/util'
import { BlobUtil } from './util/blob'
import { Properties, PropertySchema } from './properties'
import { Tags } from './tags'

export interface Icon {
    file: Blob
    content: string
    name: string
    code: string
    glyph: string
    tags: string[]
    thumb: string
    properties?: IconCustomProperty[]
    category?: string
    categoryAlternative?: string
    categoryLoading?: boolean
    shape?: string
    shapeLoading?: boolean
    shapeAlternative?: string
}

export interface IconCustomProperty {
    name: string
    value: string
    path: string
    properties?: IconCustomProperty[]
}

interface iconsDelta {
    icons: Icon[]
    properties: IconCustomProperty[]
    propertiesModified: IconCustomProperty[]
}

export interface IconProject {
    id: number
    // version: number
    // icons: Icon[]
}

const MIN_CODE_VALUE = 59647 // e900 (hex) - 1 (decimal), so after the increment, e900 is the first possible code

export class Icons {
    public icons: Icon[] = []
    public selectedIcons: Icon[] = []
    public selectedIconsDelta: iconsDelta = { icons: [], properties: [], propertiesModified: [] }
    public duplicateNames = new Set<string>()
    public duplicateCodes = new Set<string>()
    public duplicateContents = new Set<string>()
    public propertySchema: PropertySchema = {}
    private maxCodeValue = MIN_CODE_VALUE

    public addIcon(icon: Icon) {
        if (icon.code) {
            const codeValue = Util.unicodeToNumber(icon.code)
            if (codeValue > this.maxCodeValue) {
                this.maxCodeValue = codeValue
            }
        } else {
            // increment before converting the number to a hex string
            icon.code = Util.numberToUnicode(++this.maxCodeValue)
        }

        this.icons.unshift(icon)
        this.fetchIconThumb(icon)
        this.updateDuplicates()
    }

    public setIcons(icons: Icon[]) {
        let maxCodeValue = 0

        this.icons = icons

        for (const icon of icons) {
            if (icon.code) {
                const codeValue = Util.unicodeToNumber(icon.code)
                if (codeValue > maxCodeValue) {
                    maxCodeValue = codeValue
                }
            }
            this.fetchIconThumb(icon)
        }

        if (maxCodeValue <= 0) {
            maxCodeValue = MIN_CODE_VALUE
        }

        // update all icons without a code
        for (const icon of icons) {
            if (!icon.code) {
                // increment before converting the number to a hex string
                icon.code = Util.numberToUnicode(++maxCodeValue)
            }
        }

        this.maxCodeValue = maxCodeValue
        this.updateDuplicates()
    }

    public removeIcon(icon: Icon) {
        const index = this.icons.indexOf(icon)

        this.icons.splice(index, 1)
        this.updateDuplicates() // TODO: should we move this outside? -> intensive operation in bulk requests

        if (this.icons.length === 0) {
            this.maxCodeValue = MIN_CODE_VALUE
        }
    }

    public async replaceIconContent(icon: Icon, file: Blob) {
        icon.file = file
        icon.content = await BlobUtil.blobToText(file)

        this.fetchIconThumb(icon)
    }

    private fetchIconThumb(icon: Icon) {
        const file = icon.file
        const reader = new FileReader()

        reader.onloadend = function () {
            if (reader.result) {
                // TODO: resolve eslint exception
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                icon.thumb = reader.result.toString()
            }
        }

        reader.readAsDataURL(file)
    }

    public selectIcon(icon: Icon) {
        const index = this.selectedIcons.indexOf(icon)

        if (index !== -1) {
            this.selectedIcons.splice(index, 1)
        } else {
            this.selectedIcons.push(icon)
        }

        this.updateSelectedIconsDelta()
        // TODO: update selection in selecto
    }

    public reset() {
        this.maxCodeValue = MIN_CODE_VALUE
        this.setIcons([])
    }

    public resetSelection() {
        this.selectedIcons = []
    }

    public updateSelectedIconsDelta() {
        this.selectedIconsDelta.icons = Util.clone(this.selectedIcons)

        if (this.selectedIconsDelta.icons.length < 2) {
            this.selectedIconsDelta.properties = []
        } else {
            this.selectedIconsDelta.properties = this.buildCommonCustomProperties(this.selectedIconsDelta.icons)
        }

        this.selectedIconsDelta.propertiesModified = Util.clone(this.selectedIconsDelta.properties)
    }

    private buildCommonCustomProperties(icons: Icon[]) {
        const commonValuesMap = {}

        // scan initial structure of first icon
        const result = this.setupCommonCustomProperties(icons[0].properties ?? [], commonValuesMap)

        // loop over all other icons and keep only the intersected values, so we finally get the common property values
        for (const icon of icons.slice(1)) {
            this.intersectCommonCustomProperties(icon.properties ?? [], commonValuesMap)
        }

        return result
    }

    private setupCommonCustomProperties(properties: IconCustomProperty[], map: Record<string, IconCustomProperty>) {
        const result: IconCustomProperty[] = []

        for (const property of properties) {
            const p: IconCustomProperty = {
                name: property.name,
                value: Tags.split(property.value).join(', '),
                path: property.path
            }

            // console.log("->", property.path, ":", Tags.split(property.value))
            // map[property.path] = Tags.split(property.value)
            map[property.path] = p

            if (property.properties) {
                p.properties = this.setupCommonCustomProperties(property.properties, map)
            }

            result.push(p)
        }

        return result
    }

    private intersectCommonCustomProperties(properties: IconCustomProperty[], map: Record<string, IconCustomProperty>) {
        for (const property of properties) {
            if (map[property.path]) {
                const values = Tags.split(property.value)
                const p = map[property.path]
                const commonValues = Tags.split(p.value)

                // console.log("[COMPARE]", property.path, ":", values, map[property.path], "->", this.intersect(values, map[property.path]))
                p.value = Util.intersect(values, commonValues).join(', ')
            }

            if (property.properties) {
                this.intersectCommonCustomProperties(property.properties, map)
            }
        }

        return map
    }

    public updateDuplicates() {
        const duplicates = this.detectDuplicates(this.icons)

        this.duplicateNames = duplicates.names
        this.duplicateCodes = duplicates.codes
        this.duplicateContents = duplicates.contents
    }

    public detectDuplicates(icons: Icon[]) {
        const names = new Set<string>()
        const codes = new Set<string>()
        const contents = new Set<string>()
        const duplicateNames = new Set<string>()
        const duplicateCodes = new Set<string>()
        const duplicateContents = new Set<string>()

        for (const icon of icons) {
            // name
            if (names.has(icon.name)) {
                duplicateNames.add(icon.name)
            } else {
                names.add(icon.name)
            }

            // code
            if (codes.has(icon.code)) {
                duplicateCodes.add(icon.code)
            } else {
                codes.add(icon.code)
            }

            // svg content
            if (contents.has(icon.content)) {
                duplicateContents.add(icon.content)
            } else {
                contents.add(icon.content)
            }
        }

        return {
            names: duplicateNames,
            codes: duplicateCodes,
            contents: duplicateContents
        }
    }

    public isDuplicate(icon: Icon) {
        return (
            this.duplicateNames.has(icon.name) ||
            this.duplicateCodes.has(icon.code) ||
            this.duplicateContents.has(icon.content)
        )
    }

    public updatePropertySchema() {
        this.propertySchema = Properties.getSchema(this.icons)
    }

    public updateProperties() {
        this.updatePropertySchema()

        for (const icon of this.icons) {
            icon.properties = Properties.computeProperties(icon.properties ?? [], this.propertySchema)
        }
    }

    public deleteProperty(propertyPath: string) {
        for (const icon of this.icons) {
            icon.properties = Properties.deleteProperty(icon.properties ?? [], propertyPath)
        }
    }

    public renameProperty(propertyPath: string, name: string) {
        // name can not be an empty string
        if (name.trim().length === 0) {
            throw new Error('Empty property name.')
        }

        for (const icon of this.icons) {
            icon.properties = Properties.renameProperty(icon.properties ?? [], propertyPath, name)
        }
    }

    public moveProperty(propertyPath: string, propertyPathNew: string) {
        for (const icon of this.icons) {
            icon.properties = Properties.moveProperty(icon.properties ?? [], propertyPath, propertyPathNew)
        }
    }

    public getPropertyValues(path: string): string[] {
        const propertySchema = Properties.findPropertySchema(this.propertySchema, path)
        return Util.unique(propertySchema?.values ?? [])
    }
}
