import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
    public static cache: Record<string, string> = {}
    public url = `${environment.apiUrl}/cms/icons/placeholder`
    public content = ''
    public loading = false

    constructor(private http: HttpClient) {}

    async ngOnInit() {
        this.loading = true
        this.content = await this.getContent(this.url)
        this.loading = false
    }

    getContent(url: string) {
        return new Promise<string>((resolve, reject) => {
            // try to get html from cache
            if (typeof InfoComponent.cache[url] === 'string' && InfoComponent.cache[url].trim().length > 0) {
                resolve(InfoComponent.cache[url])
                return
            }

            // request html
            this.http.get(url, { responseType: 'text' }).subscribe(html => {
                InfoComponent.cache[url] = html
                resolve(html)
            }, reject)
        })
    }

    onClick(event: MouseEvent) {
        const element = event.target
        let prevent = true

        if (element instanceof HTMLElement) {
            const closestAnchorElement = element.closest('a, .iframe-wrapper')

            if (closestAnchorElement instanceof HTMLAnchorElement) {
                console.log('click', closestAnchorElement.href, closestAnchorElement.target)

                if (closestAnchorElement.target === '_blank') {
                    prevent = false
                } else {
                    // TODO: handle internal redirect (if internal url)
                }
            }
        }

        if (prevent) {
            event.preventDefault()
            event.stopPropagation()
            event.stopImmediatePropagation()
        }
    }
}
