import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core'
import { fromEvent, Observable, Subscription } from 'rxjs'

export interface SubmenuSection {
    type: 'white' | 'grey' | 'custom'
    elements: SubmenuElements
}

export interface SubmenuElement {
    type?: 'element'
    enabled?: boolean
    label: string
    icon?: string
    iconViewBox?: string
    iconRight?: string
    iconRightViewBox?: string
    value?: string
    readonly?: boolean
    active?: boolean
    clicked?: (element: SubmenuElement) => void
    clickedAsync?: (element: SubmenuElement) => Promise<void>
}

export interface SubmenuDivider {
    type: 'divider'
    enabled?: boolean
}

export type SubmenuElements = (SubmenuElement | SubmenuDivider)[]

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnDestroy {
    @Input() icon = ''
    @Input() iconViewBox = '-10 0 1044 1024'
    @Input() label = ''
    @Input() sections: SubmenuSection[] = []
    @Output() beforeShow = new EventEmitter<void>()
    // TODO: resolve eslint exception
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() select = new EventEmitter<SubmenuElement>()
    @ViewChild('button') buttonRef?: ElementRef<HTMLElement>
    @ViewChild('submenu') submenuRef?: ElementRef<HTMLElement>

    private clickObserverSubscription: Subscription = new Subscription()
    public showSubmenu = false

    ngOnDestroy() {
        this.clickObserverSubscription.unsubscribe()
    }

    // --

    show() {
        this.beforeShow.emit()
        this.showSubmenu = true
        this.initOffClickObserver()
    }

    hide() {
        this.clickObserverSubscription.unsubscribe()
        this.showSubmenu = false
    }

    initOffClickObserver() {
        const clickObservable: Observable<Event> = fromEvent(document, 'click')

        this.clickObserverSubscription = clickObservable.subscribe((event: Event) => {
            const target = event.target as HTMLElement

            console.log('global click')
            if (!this.submenuRef?.nativeElement.contains(target) && !this.buttonRef?.nativeElement.contains(target)) {
                this.hide()
            }
        })
    }

    computeSubmenuClass(element: SubmenuElement) {
        return {
            readonly: element.readonly,
            active: element.active
        }
    }

    // --

    onClick() {
        if (!this.showSubmenu) {
            this.show()
        } else {
            this.hide()
        }
    }

    async onSubmenuClick(event: Event, element: SubmenuElement) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        if (!element.readonly) {
            if (element.clicked) {
                element.clicked(element)
            } else if (element.clickedAsync) {
                await element.clickedAsync(element)
            }

            this.select.emit(element)
            this.hide()
        }
    }
}
