import { Component, Input } from '@angular/core'
import { Icon } from '../core/icons'
import { DownloadUtil } from '../core/util/download'
import { IconsUI } from '../core/icons-ui'
import { SubmenuSection } from '../dropdown/dropdown.component'
import { Mapping } from '../core/mapping'
import { Notification } from '../core/notification'

@Component({
    selector: 'app-dropdown-download',
    templateUrl: './dropdown-download.component.html',
    styleUrls: ['./dropdown-download.component.scss']
})
export class DropdownDownloadComponent {
    @Input() core: IconsUI = new IconsUI()
    @Input() icons: Icon[] = []

    sections: SubmenuSection[] = []

    constructor() {
        this.sections = [
            {
                type: 'white',
                elements: [
                    {
                        label: $localize`:@@download-dropdown.iconfont:Download Iconfont`,
                        icon: 'M480 6v846l-286 -286l-61 61l359 358l30 29l30 -29l359 -358l-61 -61l-286 286v-846h-84z',
                        clickedAsync: this.onBuildIconfont.bind(this),
                        active: true
                    },
                    {
                        label: $localize`:@@download-dropdown.icons.svg:Download Icons ( svg )`,
                        icon: 'M480 6v846l-286 -286l-61 61l359 358l30 29l30 -29l359 -358l-61 -61l-286 286v-846h-84z',
                        clickedAsync: this.onDownloadIcons.bind(this)
                    },
                    {
                        label: $localize`:@@download-dropdown.icons.png:Download Icons ( png )`,
                        icon: 'M480 6v846l-286 -286l-61 61l359 358l30 29l30 -29l359 -358l-61 -61l-286 286v-846h-84z',
                        clickedAsync: this.onDownloadPngIcons.bind(this)
                    },
                    {
                        label: $localize`:@@download-dropdown.mapping:Download Mapping`,
                        icon: 'M480 6v846l-286 -286l-61 61l359 358l30 29l30 -29l359 -358l-61 -61l-286 286v-846h-84z',
                        clicked: this.onDownloadMapping.bind(this)
                    }
                ]
            }
        ]
    }

    // --

    async onDownloadIcons() {
        this.core.loading = true

        const blob = await this.core.api?.downloadIcons(this.icons)
        if (blob) {
            DownloadUtil.serveBlob(blob)
        } else {
            Notification.showError()
        }

        this.core.loading = false
    }

    async onDownloadPngIcons() {
        this.core.loading = true

        const blob = await this.core.api?.downloadPngIcons(this.icons)
        if (blob) {
            DownloadUtil.serveBlob(blob)
        } else {
            Notification.showError()
        }

        this.core.loading = false
    }

    async onBuildIconfont() {
        await this.core.createIconfont(this.icons, true)
    }

    onDownloadMapping() {
        this.core.loading = true

        const result = Mapping.createDownloadMapping(this.icons)
        DownloadUtil.serveJson(result, 'mapping.json')

        this.core.loading = false
    }
}
