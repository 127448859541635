import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { fromEvent, Observable, Subscription } from 'rxjs'

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
    @Input() title = ''
    // TODO: resolve eslint exception
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close = new EventEmitter<void>()
    @ViewChild('content') contentRef?: ElementRef<HTMLElement>

    private clickObserverSubscription: Subscription = new Subscription()

    ngOnInit() {
        this.initOffClickObserver()
    }

    ngOnDestroy() {
        this.clickObserverSubscription.unsubscribe()
    }

    // --

    initOffClickObserver() {
        const clickObservable: Observable<Event> = fromEvent(document, 'mousedown')

        this.clickObserverSubscription = clickObservable.subscribe((event: Event) => {
            const target = event.target as HTMLElement

            if (!this.contentRef?.nativeElement.contains(target)) {
                this.onClose()
            }
        })
    }

    onClose() {
        this.close.emit()
    }
}
