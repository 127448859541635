<div class="icons-overview__header clearfix">
    <!-- left -->
    <div class="float-left clearfix">
        <div class="float-left upload-info-wrapper" (mouseover)="onShowUploadInfo()" (mouseout)="onHideUploadInfo()">
            <!--<div class="upload-info-btn btn" (click)="onShowUploadInfo()">
                <app-svg-icon
                    path="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z"
                    viewBox="0 0 24 24"
                ></app-svg-icon>
            </div>-->
            <label class="file-upload-input btn-c">
                <input
                    type="file"
                    (change)="onFileInput($event)"
                    accept="image/svg+xml, application/json, font/ttf, application/vnd.ms-fontobject, .ttf, .eot, .woff, .svg"
                    multiple>
                <app-svg-icon
                    path="M522 8l-29 28l-285 284l59 59l214 -215v673h82v-673l214 215l59 -59l-285 -284zM156 918v82h732v-82h-732z"
                    viewBox="-10 0 1044 1024"
                ></app-svg-icon>
                <div i18n="@@header.upload">Import</div>
            </label>
            <div *ngIf="uploadInfoVisible" class="upload-info-popup text-left noselect">
                <h4 class="m-vt-0 m-vb-1" i18n="@@upload-info.title">Supported Filetypes</h4>
                <table>
                    <tr>
                        <td class="semi-bold" i18n="@@upload-info.iconfonts">Iconfonts</td>
                        <td>*.woff, *.eot, *.ttf</td>
                    </tr>
                    <tr>
                        <td class="semi-bold" i18n="@@upload-info.icons">Icons</td>
                        <td>*.svg</td>
                    </tr>
                </table>
            </div>
        </div>
        <button
            *ngIf="core.icons.length > 0"
            class="float-left m-hl-1 btn-t-b b-transparent"
            (click)="onReset($event)"
        >
            <app-svg-icon
                path="M134 190v-143h-77v310h310v-78h-202q29 -43 67.5 -79t83.5 -61.5t97 -39t109 -13.5q89 0 166.5 33t135.5 91t91 135.5t33 166.5t-33 166.5t-91 135.5t-135.5 91t-166.5 33t-166.5 -33t-135.5 -91t-91 -135.5t-33 -166.5h-78q0 105 39.5 196.5t108 160t160 108t196.5 39.5 t196.5 -39.5t160 -108t108 -160t39.5 -196.5t-39.5 -196.5t-108 -160t-160 -108t-196.5 -39.5q-58 0 -113 12.5t-104.5 36.5t-92.5 57.5t-78 75.5z"
                viewBox="-10 0 1046 1024"
            ></app-svg-icon>
            <div i18n="@@header.reset">Reset</div>
        </button>

        <app-dropdown-download
            *ngIf="core.icons.length > 0"
            [core]="core"
            [icons]="core.icons"
            class="float-left m-hl-1"
        ></app-dropdown-download>
        <button
            class="float-left m-hl-1 btn-t-b b-transparent"
            (click)="onApply()"
        >
            <app-svg-icon
                path="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                viewBox="0 0 24 24"
            ></app-svg-icon>
            <div i18n="@@header.apply">Apply</div>
        </button>
    </div>

    <div class="float-right">
        <app-dropdown
            i18n-label="@@header.sort.submenu"
            label="Sort"
            icon="M19 17H22L18 21L14 17H17V3H19M2 17H12V19H2M6 5V7H2V5M2 11H9V13H2V11Z"
            iconViewBox="0 0 24 24"
            [sections]="sortOptions"
            (beforeShow)="onBeforeShowSortDropdown()"
        ></app-dropdown>
    </div>
</div>
