<div class="m-vb-2">
  <div class="property-name-wrapper m-vb-1">
    <label class="property-name semi-bold ellipsis">{{ property.name }}</label>

    <app-svg-icon
      path="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
      viewBox="0 0 24 24"
      i18n-title="@@custom-property.delete"
      title="Delete"
      (click)="onDeleteCustomProperty()"
    ></app-svg-icon>
    <app-svg-icon
      path="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
      viewBox="0 0 24 24"
      i18n-title="@@custom-property.edit"
      title="Edit"
      (click)="onEditCustomProperty()"
    ></app-svg-icon>
    <app-svg-icon
      path="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
      viewBox="0 0 24 24"
      i18n-title="@@custom-property.add"
      title="Add"
      (click)="onAddCustomProperty()"
    ></app-svg-icon>
  </div>

  <div class="ng-autocomplete">
    <ng-autocomplete
      [data]="values"
      [initialValue]="value ?? property.value"
      (selected)='onSelect($event)'
      (inputChanged)='onInputChanged($event)'
      (inputFocused)='onInputFocus()'
      (inputCleared)='onInputCleared()'
      (closed)='onClosed()'
      [itemTemplate]="itemTemplate"
    ></ng-autocomplete>

    <ng-template #itemTemplate let-item>
      <a [innerHTML]="item"></a>
    </ng-template>

    <app-svg-icon
      *ngIf="!property.value"
      path="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
      viewBox="0 0 24 24"
      class="pointer-events-none cursor-pointer"
    ></app-svg-icon>
  </div>

  <div class="properties m-vt-2">
    <app-custom-property
      *ngFor="let childProperty of property.properties ?? []"
      [property]="childProperty"
      [core]="core"
      (change)="change.emit($event)"
      (add)="add.emit($event)"
      (delete)="delete.emit($event)"
      class="m-vb-2"
    ></app-custom-property>
  </div>
</div>
