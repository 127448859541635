import { registerLocaleData } from '@angular/common'
import { APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core'
import { loadTranslations } from '@angular/localize'

// @source: https://whiteduck.de/how-to-approach-angular-internationalization-i18n-in-2022/
@Injectable({
    providedIn: 'root'
})
export class I18n {
    static supportedLanguages = ['en', 'de']
    locale = 'en'

    async setLocale() {
        // 1. check if user has explicit/preferred locale stored in local-storage
        let userLocale = I18n.getLocaleFromLocalStorage()

        // 2. if no locale locally found, try to get browser locale
        if (!userLocale) {
            userLocale = I18n.getBrowserLocale()
        }

        // 3. use determined locale or keep the default one otherwise
        if (userLocale && I18n.isLocaleSupported(userLocale)) {
            this.locale = userLocale
        }

        console.log('use locale:', this.locale)

        // Use web pack magic string to only include required locale data
        const localeModule = await import(
            /* webpackInclude: /(de|en)\.mjs$/ */
            `/node_modules/@angular/common/locales/${this.locale}.mjs`
        )

        // Set locale for built in pipes, etc.
        registerLocaleData(localeModule.default)

        // Load translation file
        const localeTranslationsModule = await import(`src/assets/i18n/${this.locale}.json`)

        // Load translations for the current locale at run-time
        loadTranslations(localeTranslationsModule.default)
    }

    public static getBrowserLocale() {
        // @ts-ignore
        const browserLanguage = (navigator.language || navigator.userLanguage) ?? ''

        return browserLanguage.split(/[-_]/)[0]
    }

    public static getLocaleFromLocalStorage() {
        return localStorage.getItem('locale')
    }

    public static setLocaleToLocalStorage(locale: string) {
        if (I18n.isLocaleSupported(locale)) {
            localStorage.setItem('locale', locale)
            return true
        }
        return false
    }

    public static isLocaleSupported(locale: string) {
        return I18n.supportedLanguages.includes(locale)
    }
}

// Load locale data at app start-up
function setLocale() {
    return {
        provide: APP_INITIALIZER,
        useFactory: (i18n: I18n) => () => i18n.setLocale(),
        deps: [I18n],
        multi: true
    }
}

// Set the runtime locale for the app
function setLocaleId() {
    return {
        provide: LOCALE_ID,
        useFactory: (i18n: I18n) => i18n.locale,
        deps: [I18n]
    }
}

export const I18nModule = {
    setLocale: setLocale,
    setLocaleId: setLocaleId
}
