<div
    class="element"
    draggable="true"
    [ngClass]="{ dragover: dragover, dragged: dragged, collapsed: collapsed }"
    (dragstart)="onDragStart($event)"
    (dragend)="onDragEnd()"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave()"
    (drop)="onDrop($event)"
>
    <div class="name-wrapper semi-bold cursor-pointer">
        <div class="icon-wrapper" (click)="onToggleCollapsable()">
            <app-svg-icon
                path="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                viewBox="0 0 24 24"
            ></app-svg-icon>
        </div>
        <div class="name ellipsis" (click)="onLabelClick(element)">{{ element.name }}</div>
    </div>
    <div class="children">
        <app-sidebar-overview-element
            *ngFor="let o of element.properties | keyvalue"
            [element]="o.value"
            [parent]="element"
            [core]="core"
        ></app-sidebar-overview-element>
        <div
            *ngFor="let v of computeUnique(element.values)"
            class="value cursor-pointer ellipsis"
            [ngClass]="{ active: core.filter.properties[element.path] && core.filter.properties[element.path].includes(v) }"
            (click)="onValueClick(element, v)"
        >{{ v }}</div>
    </div>
</div>
