<!-- TODO: i18n -->
<app-modal *ngIf="core.apiModal" title="API" (close)="onClose()">
  <div class="tabs m-vb-3 noselect">
    <button
      *ngFor="let o of apiEndpoints | keyvalue"
      [ngClass]="computeEndpointClass(o.value.name)"
      (click)="onSelectEndpoint(o.value.name)"
    >{{ o.value.label }}</button>
  </div>
  <div class="line-height-paragraph">
    <b class="noselect" i18n="@@api-modal.parameter">Parameter</b>
    <!-- TODO: remove debug text -->
    <p class="noselect">{{ selectedEndpoint.description || '-- "' + selectedEndpoint.name + '" description --' }}</p>
    <div class="clearfix noselect">
      <b class="float-left" i18n="@@api-modal.example">Example</b>
      <button class="float-right" i18n="@@api-modal.copy" (click)="onCodeCopy()">Copy</button>
    </div>
    <pre>{{ selectedEndpoint.code || '-- "' + selectedEndpoint.name + '" code --' }}</pre>
  </div>
</app-modal>
