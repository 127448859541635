import { Component, Input } from '@angular/core'
import { Icon } from '../../core/icons'
import { EMPTY_ICON, IconsUI } from '../../core/icons-ui'

@Component({
    selector: 'app-icon-element',
    templateUrl: './icon-element.component.html',
    styleUrls: ['./icon-element.component.scss']
})
export class IconElementComponent {
    @Input() core: IconsUI = new IconsUI()
    @Input() icon: Icon = EMPTY_ICON
    dragover = false

    onDragOver(event: Event) {
        event.preventDefault()
        event.stopPropagation()
        this.dragover = true
    }

    onDragLeave() {
        this.dragover = false
    }

    async onDrop(event: DragEvent) {
        event.preventDefault()

        this.dragover = false

        if (event.dataTransfer) {
            const file = event.dataTransfer.files[0]

            // only svg icons are supported
            if (file.type === 'image/svg+xml') {
                await this.core.replaceIconContentWithConfirm(this.icon, file)
            }
        }
    }

    async onDelete(event: Event) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        await this.core.deleteIconsWithConfirm([this.icon])
    }
}
