<div
    class="content"
    [ngClass]="{dragover: dragover}"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave()"
    (drop)="onDrop($event)"
>
    <h1 class="m-vb-3" i18n="@@ai.title">AI Icon Recognition</h1>

    <div>
        <div class="icon-container m-vb-3" >
            <img *ngIf="imageBase64" [src]="imageBase64"/>
            <p *ngIf="!imageBase64" i18n="@@ai.upload.text">Drag and drop an icon into the window or upload an image</p>
        </div>

        <div>
            <label class="file-upload-input btn-c">
                <input type="file" (change)="onFileInput($event)" accept="image/png,image/svg+xml,.png,.svg">
                <app-svg-icon
                    path="M522 8l-29 28l-285 284l59 59l214 -215v673h82v-673l214 215l59 -59l-285 -284zM156 918v82h732v-82h-732z"
                    viewBox="-10 0 1044 1024"
                ></app-svg-icon>
                <div i18n="@@ai.upload">Upload image</div>
            </label>
        </div>

        <div *ngIf="response" class="m-vt-3 m-vb-3" style="font-size: 16px">
            <strong i18n="@@ai.predicted">Predicted</strong>:
            <span>{{ response.predictions }}, {{ response.predictions_alt }}</span>
        </div>

        <div *ngIf="response && response.previews" class="previews m-vb-3">
            <div *ngFor="let previewDataUrl of response.previews[0]" class="preview">
                <img [src]="previewDataUrl"/>
            </div>
        </div>

        <a class="m-vt-3 m-vb-3 block" href="/training" i18n="@@ai.training">Training</a>
    </div>
</div>
