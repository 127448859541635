export class DownloadUtil {
    public static serveBlob(blob: Blob, filename?: string) {
        const downloadUrl = window.URL.createObjectURL(blob)

        DownloadUtil.serve(downloadUrl, filename)
    }

    public static serveJson(data: object | string, filename?: string) {
        const dataString = typeof data === 'string' ? data : JSON.stringify(data)
        const dataUrl = 'data:text/json;charset=utf-8,' + encodeURIComponent(dataString)

        DownloadUtil.serve(dataUrl, filename)
    }

    public static serve(downloadUrl: string, filename?: string) {
        const downloadLink = document.createElement('a')

        downloadLink.href = downloadUrl

        if (filename) {
            downloadLink.setAttribute('download', filename)
        }

        document.body.appendChild(downloadLink)
        downloadLink.click()
    }
}
