import { Component, Input, OnInit } from '@angular/core'
import { IconsUI } from '../core/icons-ui'
import { Product } from '../core/icons-api'
import { Notification } from '../core/notification'

@Component({
    selector: 'app-modal-purchase',
    templateUrl: './modal-purchase.component.html',
    styleUrls: ['./modal-purchase.component.scss']
})
export class ModalPurchaseComponent implements OnInit {
    @Input() core: IconsUI = new IconsUI()
    products: Product[] = []

    ngOnInit(): void {
        this.core.purchaseModal.subscribe(async displayModal => {
            if (displayModal) {
                await this.loadProducts()
            }
        })
    }

    computeActionLabel(product: Product) {
        if (product.status === 'subscribed') {
            return $localize`:@@purchase.unsubscribed:Unsubscribe`
        }
        return product.status === 'paid' ? $localize`:@@purchase.bought:Bought` : $localize`:@@purchase.buy:Buy`
    }

    async loadProducts() {
        if (this.core.session) {
            // TODO: show spinner

            const response = await this.core.api?.products({
                accessToken: this.core.session.access_token
            })

            if (response) {
                if (response.paddle.sandbox) {
                    // @ts-ignore
                    window.Paddle.Environment.set('sandbox')
                }
                // @ts-ignore
                window.Paddle.Setup({ vendor: response.paddle.vendor_id })

                this.products = response.products
            } else {
                Notification.showError()
            }
        }
    }

    onBuy(product: Product) {
        if (product.status === 'paid') {
            return
        }

        if (product.status === 'subscribed') {
            // TODO: are you sure
            // TODO: if subscribed -> cancel
            return
        }

        // @source https://developer.paddle.com/reference/ZG9jOjI1MzU0MDIw-checkout-parameters
        // @ts-ignore
        window.Paddle.Checkout.open({
            allowQuantity: false,
            product: product.product_id,
            email: this.core.session?.user.email
        })
    }

    onClose() {
        this.core.purchaseModal.next(false)
    }
}
