import { Component, Input } from '@angular/core'
import { IconsUI, SortProperty } from '../../core/icons-ui'
import { SubmenuElement, SubmenuSection } from '../../dropdown/dropdown.component'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    @Input() core: IconsUI = new IconsUI()
    sortOptions: SubmenuSection[] = []
    uploadInfoVisible = false

    onBeforeShowSortDropdown() {
        this.sortOptions = [
            {
                type: 'white',
                elements: [
                    {
                        label: $localize`:@@sort.name:Order By Name`,
                        value: 'name',
                        iconRight:
                            this.core.sortProperty === 'name'
                                ? 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'
                                : undefined,
                        iconRightViewBox: '0 0 24 24',
                        clicked: this.onSortChange.bind(this)
                    },
                    {
                        label: $localize`:@@sort.code:Order By Code`,
                        value: 'code',
                        iconRight:
                            this.core.sortProperty === 'code'
                                ? 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'
                                : undefined,
                        iconRightViewBox: '0 0 24 24',
                        clicked: this.onSortChange.bind(this)
                    }
                ]
            }
        ]
    }

    computeSortLabel() {
        if (this.core.sortProperty === 'name') {
            return $localize`:@@sort.name.short:By Name`
        }
        if (this.core.sortProperty === 'code') {
            return $localize`:@@sort.code.short:By Code`
        }
        return ''
    }

    // --

    async onApply() {
        await this.core.createIconfont(this.core.icons)
    }

    async onFileInput(event: Event) {
        if (event.target instanceof HTMLInputElement && event.target.files) {
            await this.core.processFiles(event.target.files)
        }
    }

    async onReset(event: Event) {
        event.preventDefault()
        await this.core.resetWithConfirm()
    }

    onSortChange(element: SubmenuElement) {
        this.core.sortIcons(element.value as SortProperty)
    }

    onShowUploadInfo() {
        this.uploadInfoVisible = true
    }

    onHideUploadInfo() {
        this.uploadInfoVisible = false
    }
}
