<!-- TODO: i18n -->
<app-modal *ngIf="core.purchaseModal.value" title="Purchase" (close)="onClose()">
  <div class="products m-vb-2">
    <div *ngFor="let product of products" class="product cursor-pointer">
      <h3 class="m-vb-0">{{ product.name }}</h3>
      <p>{{ product.description }}</p>
      <!-- TODO: i18n -->
      <button
        class="m-vt-2 m-vb-1"
        [ngClass]="{
          active: !product.status,
          'pointer-events-none': product.status === 'paid'
        }"
        (click)="onBuy(product)"
      >
        {{ computeActionLabel(product) }}
      </button>
    </div>
  </div>
</app-modal>
