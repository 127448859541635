import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { Prompt } from '../core/prompt'

@Component({
    selector: 'app-prompt',
    templateUrl: './prompt.component.html',
    styleUrls: ['./prompt.component.scss']
})
export class PromptComponent {
    @Input() confirmLabel: string = $localize`:@@prompt.confirm:Yes`
    @Input() cancelLabel: string = $localize`:@@prompt.cancel:Cancel`
    @ViewChild('content') contentRef?: ElementRef<HTMLElement>

    prompt = Prompt

    onConfirm() {
        this.prompt.data?.confirm()
        this.prompt.data = undefined
    }

    onCancel() {
        this.prompt.data?.cancel()
        this.prompt.data = undefined
    }

    onBackgroundClick(event: Event) {
        const target = event.target as HTMLElement

        if (!this.contentRef?.nativeElement.contains(target)) {
            this.onCancel()
        }
    }
}
