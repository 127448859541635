import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { IconOverviewComponent } from './icon-overview/icon-overview.component'
import { DropdownComponent } from './dropdown/dropdown.component'
import { I18nModule } from './i18n.module'
import { SidebarSingleComponent } from './sidebar-single/sidebar-single.component'
import { SidebarMultipleComponent } from './sidebar-multiple/sidebar-multiple.component'
import { ModalApiDocumentationComponent } from './modal-api-documentation/modal-api-documentation.component'
import { ModalDeltaComponent } from './modal-delta/modal-delta.component'
import { HeaderComponent } from './icon-overview/header/header.component'
import { SvgIconComponent } from './svg-icon/svg-icon.component'
import { DropdownDownloadComponent } from './dropdown-download/dropdown-download.component'
import { NotificationsComponent } from './notifications/notifications.component'
import { PromptComponent } from './prompt/prompt.component'
import { ModalPropertyComponent } from './modal-property/modal-property.component'
import { CustomPropertyComponent } from './custom-property/custom-property.component'
import { AutocompleteLibModule } from 'angular-ng-autocomplete'
import { SidebarOverviewComponent } from './sidebar-overview/sidebar-overview.component'
import { SidebarOverviewElementComponent } from './sidebar-overview/sidebar-overview-element/sidebar-overview-element.component'
import { IconElementComponent } from './icon-overview/icon-element/icon-element.component'
import { ModalLoginComponent } from './modal-login/modal-login.component'
import { ModalSettingsComponent } from './modal-settings/modal-settings.component'
import { AgVirtualScrollModule } from 'ag-virtual-scroll'
import { ModalComponent } from './modal/modal.component'
import { ModalPurchaseComponent } from './modal-purchase/modal-purchase.component'
import { HttpRequestInterceptor } from './core/http-request-interceptor'
import { HeaderMainComponent } from './header-main/header-main.component'
import { ModalLoginExpiredComponent } from './modal-login-expired/modal-login-expired.component'
import { IconTrainingComponent } from './icon-training/icon-training.component'
import { AiComponent } from './ai/ai.component'
import { InfoComponent } from './info/info.component'
import { LoadingComponent } from './loading/loading.component'

@NgModule({
    declarations: [
        AppComponent,
        IconOverviewComponent,
        DropdownComponent,
        SidebarSingleComponent,
        SidebarMultipleComponent,
        ModalApiDocumentationComponent,
        ModalDeltaComponent,
        HeaderComponent,
        SvgIconComponent,
        DropdownDownloadComponent,
        NotificationsComponent,
        PromptComponent,
        ModalPropertyComponent,
        CustomPropertyComponent,
        SidebarOverviewComponent,
        SidebarOverviewElementComponent,
        IconElementComponent,
        ModalLoginComponent,
        ModalSettingsComponent,
        ModalComponent,
        ModalPurchaseComponent,
        HeaderMainComponent,
        ModalLoginExpiredComponent,
        IconTrainingComponent,
        AiComponent,
        InfoComponent,
        LoadingComponent
    ],
    imports: [BrowserModule, AppRoutingModule, HttpClientModule, AutocompleteLibModule, AgVirtualScrollModule],
    providers: [
        I18nModule.setLocale(),
        I18nModule.setLocaleId(),
        [{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }]
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
