import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IconCustomProperty } from '../core/icons'
import { Prompt } from '../core/prompt'
import { IconsUI } from '../core/icons-ui'
import { Properties } from '../core/properties'

@Component({
    selector: 'app-custom-property',
    templateUrl: './custom-property.component.html',
    styleUrls: ['./custom-property.component.scss']
})
export class CustomPropertyComponent {
    @Input() core: IconsUI = new IconsUI()
    @Input() property: IconCustomProperty = { name: '', value: '', path: '' }
    @Output() change = new EventEmitter<IconCustomProperty>()
    @Output() add = new EventEmitter<{ property: IconCustomProperty; newProperty: IconCustomProperty }>()
    @Output() delete = new EventEmitter<IconCustomProperty>()

    values: string[] = []
    value?: string // this value should initially back up the property value, so the value won't be re-rendered after input-change

    onEditCustomProperty() {
        this.core.selectedProperty = this.property
    }

    async onAddCustomProperty() {
        const defaultName = 'Property'
        const name = Properties.getUniqueName(defaultName, this.property.properties ?? [])
        const newProperty: IconCustomProperty = {
            name: name,
            value: '',
            path: Properties.buildPath(this.property.path, name)
        }

        if (!this.property.properties) {
            this.property.properties = []
        }

        this.property.properties.push(newProperty)

        this.add.emit({
            property: this.property,
            newProperty: newProperty
        })
        this.core.updateProperties()
        await this.core.saveDebounced()

        this.core.selectedProperty = newProperty
    }

    async onDeleteCustomProperty() {
        const confirmed = await Prompt.confirm({
            title: $localize`:@@delete-property-modal.title:Delete Property`,
            text: $localize`:@@delete-property-modal.text:Are you sure you want to delete this property?`,
            confirmLabel: $localize`:@@delete-property-modal.confirm:Yes, delete`
        })

        if (confirmed) {
            this.core.deleteProperty(this.property.path)
            this.delete.emit(this.property)
            await this.core.saveDebounced()
        }
    }

    async onSelect(item: string) {
        this.property.value = item
        this.change.emit(this.property)
        await this.core.saveDebounced()
    }

    onInputFocus() {
        // calc values only if needed
        this.values = this.core.getPropertyValues(this.property.path)
        this.value = this.property.value
    }

    async onInputChanged(val: string) {
        this.property.value = val.trim()
        this.change.emit(this.property)
        await this.core.saveDebounced()
    }

    async onInputCleared() {
        this.property.value = ''
        this.change.emit(this.property)
        await this.core.saveDebounced()
    }

    onClosed() {
        this.core.updatePropertySchema()
    }
}
