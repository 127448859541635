export class BlobUtil {
    public static async blobToBase64(blob: Blob) {
        return new Promise((resolve: (svg: string) => void, reject) => {
            const reader = new FileReader()

            reader.onload = () => resolve(reader.result?.toString() ?? '')
            reader.onerror = error => reject(error)

            reader.readAsDataURL(blob)
        })
    }

    public static bufferToBlob(buffer: number[]) {
        return new Blob([new Uint8Array(buffer).buffer], { type: 'application/zip' })
    }

    public static svgToBlob(content: string) {
        return new Blob([content], { type: 'image/svg+xml' })
    }

    public static async blobToText(file: Blob) {
        return new Promise<string>((resolve: (text: string) => void, reject) => {
            const reader = new FileReader()

            reader.onload = () => {
                resolve(reader.result?.toString() ?? '')
            }

            reader.onerror = reject

            reader.readAsText(file, 'UTF-8')
        })
    }
}
