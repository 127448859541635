import { Component, Input, OnInit } from '@angular/core'
import { EMPTY_ICON, IconsUI } from '../core/icons-ui'
import { DownloadUtil } from '../core/util/download'
import { BlobUtil } from '../core/util/blob'
import { Clipboard } from '../core/clipboard'
import { IconCustomProperty } from '../core/icons'
import { Properties } from '../core/properties'
import { Tags } from '../core/tags'
import { Util } from '../core/util/util'
import { Notification } from '../core/notification'
import { DebouncedFunc } from 'lodash-es'
import * as _ from 'lodash'

@Component({
    selector: 'app-sidebar-single',
    templateUrl: './sidebar-single.component.html',
    styleUrls: ['./sidebar-single.component.scss']
})
export class SidebarSingleComponent implements OnInit {
    @Input() core: IconsUI = new IconsUI()

    uploadInputId = Util.randomHash()
    thumbDragover = false
    categoryValueTmp = ''
    sendCategoryDebounced: DebouncedFunc<() => Promise<void>>
    shapeValueTmp = ''
    sendShapeDebounced: DebouncedFunc<() => Promise<void>>

    constructor() {
        this.sendCategoryDebounced = _.debounce(this.sendCategory, 1000)
        this.sendShapeDebounced = _.debounce(this.sendShape, 1000)
    }

    async ngOnInit() {
        await this.core.loadCategories()
        await this.core.loadShapes()
    }

    // --

    async replaceIconContent(file: Blob) {
        const icon = this.computeIcon()
        await this.core.replaceIconContentWithConfirm(icon, file)
    }

    async sendCategory() {
        const newCategory = this.categoryValueTmp.trim()
        const icon = this.computeIcon()

        // validate category name
        if (newCategory.length === 0 || newCategory === '-') {
            return
        }

        if (newCategory !== icon.category && this.core.session?.access_token) {
            icon.categoryLoading = true

            const response = await this.core.api?.feedback(
                'categories',
                icon.file,
                newCategory,
                this.core.session?.access_token
            )

            icon.categoryLoading = false

            // icon.category = newCategory
            await this.core.saveDebounced()

            console.log(response)
        }
    }

    async sendShape() {
        const newShape = this.shapeValueTmp.trim()
        const icon = this.computeIcon()

        // validate shape name
        if (newShape.length === 0 || newShape === '-') {
            return
        }
        console.log('send shape')

        if (newShape !== icon.shape && this.core.session?.access_token) {
            icon.shapeLoading = true

            const response = await this.core.api?.feedback(
                'shapes',
                icon.file,
                newShape,
                this.core.session?.access_token
            )

            icon.shapeLoading = false

            // icon.shape = newShape
            await this.core.saveDebounced()

            console.log(response)
        }
    }

    // --

    computeIcon() {
        return this.core.selectedIcons[0] ?? EMPTY_ICON
    }

    computeIconThumbStyle() {
        return {
            'background-image': `url(${this.computeIcon().thumb})`
        }
    }

    computeTags() {
        return this.core.selectedIconsDelta.icons[0].tags.join(', ')
    }

    computeProperties() {
        return this.computeIcon().properties ?? []
    }

    // -- handler

    async onFileReplace(event: Event) {
        if (event.target instanceof HTMLInputElement && event.target.files) {
            await this.replaceIconContent(event.target.files[0])
        }
    }

    onThumbDragOver(event: Event) {
        event.preventDefault()
        this.thumbDragover = true
    }

    onThumbDragLeave() {
        this.thumbDragover = false
    }

    async onThumbDrop(event: DragEvent) {
        event.preventDefault()

        this.thumbDragover = false

        if (event.dataTransfer) {
            const file = event.dataTransfer.files[0]

            // only svg icons are supported
            if (file.type === 'image/svg+xml') {
                await this.replaceIconContent(file)
            }
        }
    }

    async onDownloadSvg(event: Event) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        this.core.loading = true

        const icon = this.computeIcon()
        const svgBase64 = await BlobUtil.blobToBase64(icon.file)
        DownloadUtil.serve(svgBase64, `${icon.name}.svg`)

        this.core.loading = false
    }

    async onDownloadPng(event: Event) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        this.core.loading = true

        const blob = await this.core.api?.downloadPngIcons([this.computeIcon()])
        if (blob) {
            DownloadUtil.serveBlob(blob)
        } else {
            Notification.showError()
        }

        this.core.loading = false
    }

    async onCopyName() {
        const icon = this.computeIcon()
        await Clipboard.copyWithNotification(icon.name)
    }

    async onCopyCode() {
        const icon = this.computeIcon()
        await Clipboard.copyWithNotification(icon.code)
    }

    async onCopyGlyph() {
        const icon = this.computeIcon()
        await Clipboard.copyWithNotification(icon.glyph)
    }

    isLoggedIn() {
        return typeof this.core.session?.access_token !== 'undefined'
    }

    // --

    async onCategoryRequest() {
        if (this.core.session?.access_token) {
            const icon = this.computeIcon()

            icon.categoryLoading = true

            const response = await this.core.api?.predict('categories', [icon], this.core.session.access_token)

            console.log('response', response)

            if (response) {
                console.log('category=', response.predictions, response.predictions_alt)

                icon.category = response?.predictions[0]
                icon.categoryAlternative = response?.predictions_alt[0]
            } else {
                Notification.showError()
            }

            icon.categoryLoading = false

            await this.core.saveDebounced()
        } else {
            Notification.showError()
        }
    }

    async onAlternativeCategorySelect() {
        const icon = this.computeIcon()

        if (this.core.session?.access_token && icon.categoryAlternative) {
            icon.categoryLoading = true

            const response = await this.core.api?.feedback(
                'categories',
                icon.file,
                icon.categoryAlternative,
                this.core.session?.access_token
            )

            icon.category = icon.categoryAlternative
            icon.categoryLoading = false

            // icon.category = newCategory

            await this.core.saveDebounced()

            console.log(response)
        }
    }

    onCategorySelect(category: string) {
        this.categoryValueTmp = category
        this.sendCategoryDebounced()
    }

    onCategoryInput(category: string) {
        // store on close
        this.categoryValueTmp = category
    }

    onCategoryClose() {
        this.sendCategoryDebounced()
    }

    // --

    async onShapeRequest() {
        if (this.core.session?.access_token) {
            const icon = this.computeIcon()

            icon.shapeLoading = true

            const response = await this.core.api?.predict('shapes', [icon], this.core.session.access_token)
            console.log('response', response)

            if (response) {
                console.log('shape=', response.predictions, response.predictions_alt)

                icon.shape = response?.predictions[0]
                icon.shapeAlternative = response?.predictions_alt[0]
            } else {
                Notification.showError()
            }

            icon.shapeLoading = false

            await this.core.saveDebounced()
        } else {
            Notification.showError()
        }
    }

    async onAlternativeShapeSelect() {
        const icon = this.computeIcon()

        if (this.core.session?.access_token && icon.shapeAlternative) {
            icon.shapeLoading = true

            const response = await this.core.api?.feedback(
                'shapes',
                icon.file,
                icon.shapeAlternative,
                this.core.session?.access_token
            )

            icon.shape = icon.shapeAlternative
            icon.shapeLoading = false

            // icon.shape = newCategory

            await this.core.saveDebounced()

            console.log(response)
        }
    }

    onShapeSelect(shape: string) {
        this.shapeValueTmp = shape
        this.sendShapeDebounced()
    }

    onShapeInput(shape: string) {
        // store on close
        this.shapeValueTmp = shape
    }

    onShapeClose() {
        this.sendShapeDebounced()
    }

    // --

    async onInputChange(event: Event, property: 'name' | 'code' | 'tags') {
        if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
            const value = event.target.value
            const icon = this.computeIcon()

            if (property === 'tags') {
                icon[property] = Tags.split(value)
            } else {
                icon[property] = value

                if (['name', 'code'].includes(property)) {
                    this.core.updateDuplicates()
                }
            }

            await this.core.saveDebounced()
        }
    }

    async onAddCustomProperty() {
        const icon = this.computeIcon()
        const defaultName = 'Property'
        const name = Properties.getUniqueName(defaultName, icon.properties ?? [])
        const newProperty: IconCustomProperty = {
            name: name,
            value: '',
            path: Properties.buildPath('', name)
        }

        if (!icon.properties) {
            icon.properties = []
        }

        icon.properties.push(newProperty)

        this.core.updateProperties()
        await this.core.saveDebounced()

        this.core.selectedProperty = newProperty
    }

    async onIconDelete(event: Event) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        await this.core.deleteIconsWithConfirm([this.computeIcon()])
    }
}
