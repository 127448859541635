import { AbstractStorage, StorageInitOptions as StorageInitOptionsBase } from './abstract-storage'
import { LocalStorage } from './driver/local-storage'
import { IndexedDb } from './driver/indexed-db'

export type StorageDriver = 'local' | 'indexed-db'

export interface StorageInitOptions extends StorageInitOptionsBase {
    driverName?: StorageDriver
}

export class Storage {
    protected static driverName: StorageDriver = 'indexed-db'
    private static driver?: AbstractStorage

    public static init(options: StorageInitOptions = {}) {
        if (options.driverName) {
            Storage.driverName = options.driverName
        }

        Storage.getDriver().init(options)
    }

    protected static getDriver(): AbstractStorage {
        if (!Storage.driver) {
            switch (Storage.driverName) {
                case 'local':
                    Storage.driver = new LocalStorage()
                    break
                case 'indexed-db':
                    Storage.driver = new IndexedDb()
                    break
                default:
                    throw new Error('Driver not support:' + Storage.driverName)
            }
        }
        return Storage.driver
    }

    public static async get(key: string, fallbackFromLocalStorage = false): Promise<string | null> {
        let value = await Storage.getDriver().get(key)

        // if no icons are available in index-db -> try to get them from localstorage
        if (value === null && fallbackFromLocalStorage && Storage.driverName === 'indexed-db') {
            const localStorage = new LocalStorage()
            value = await localStorage.get(key)
        }

        return new Promise(resolve => {
            resolve(value)
        })
    }

    public static set(key: string, valueOrNull: unknown): Promise<void> {
        return Storage.getDriver().set(key, valueOrNull)
    }

    public static async delete(key: string): Promise<void> {
        return Storage.getDriver().delete(key)
    }

    public static async keys(): Promise<string[]> {
        return Storage.getDriver().keys()
    }
}
