<app-modal *ngIf="core.userModal" i18n-title="@@settings-modal.title" title="User Settings" (close)="onClose()">
  <form autocomplete="off" (submit)="onSubmit($event)">
    <div class="m-vb-2">
      <label class="m-vb-1 semi-bold block" i18n="@@user.firstname">Firstname</label>
      <input
        type="text"
        class="input-b"
        [value]="core.session?.user?.first_name"
        i18n-placeholder="@@user.firstname.ph"
        placeholder="Enter firstname"
        #firstName>
    </div>
    <div class="m-vb-2">
      <label class="m-vb-1 semi-bold block" i18n="@@user.lastname">Lastname</label>
      <input
        type="text"
        class="input-b"
        [value]="core.session?.user?.last_name"
        i18n-placeholder="@@user.lastname.ph"
        placeholder="Enter lastname"
        #lastName>
    </div>

    <div class="m-vb-2 pointer-events-none">
      <label class="m-vb-1 semi-bold block" i18n="@@user.email">E-Mail</label>
      <input type="text" class="input-b" [value]="core.session?.user?.email" disabled>
    </div>

    <div class="m-vb-3 text-center" *ngIf="!changePassword">
      <button class="m-vb-2 m-h-auto display-flex" (click)="onChangePassword()" i18n="@@user.password.change">Change password</button>
    </div>

    <div *ngIf="changePassword">
      <div class="m-vb-2">
        <label class="m-vb-1 semi-bold block" i18n="@@user.password">Password</label>
        <input
          type="password"
          class="input-b"
          i18n-placeholder="@@user.password.ph"
          placeholder="Enter password"
          #password>
      </div>
      <div class="m-vb-3">
        <label class="m-vb-1 semi-bold block" i18n="@@user.password-repeat">Repeat password</label>
        <input
          type="password"
          class="input-b"
          i18n-placeholder="@@user.password-repeat.ph"
          placeholder="Repeat password"
          #passwordRepeat>
      </div>
    </div>

    <div *ngIf="errorMessage" class="error-message text-center m-vb-2">{{ errorMessage }}</div>

    <div class="m-vb-2 text-center">
      <button type="submit" class="m-vb-2 m-h-auto display-flex" i18n="@@save">Save</button>
    </div>
  </form>
</app-modal>
