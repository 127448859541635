<app-modal *ngIf="core.selectedProperty" i18n-title="@@property-modal.title" title="Custom property" (close)="onClose()">
  <div class="text-left m-vb-3">
    <label class="block m-vb-1 semi-bold" i18n="@@property-modal.name.label">Name</label>
    <input [value]="core.selectedProperty.name" class="width-100" #nameInput>

    <label class="block m-vb-1 semi-bold m-vt-2" i18n="@@property-modal.value.label">Value</label>
    <textarea [value]="core.selectedProperty.value" class="width-100" #valueTextarea></textarea>
  </div>
  <button (click)="onSave()" i18n="@@property-modal.save">Save</button>
</app-modal>
