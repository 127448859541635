<button class="dropdown width-100 btn-t-b b-transparent" [ngClass]="{ active: showSubmenu, rounded: !label }" (click)="onClick()" #button>
    <app-svg-icon [path]="icon" [viewBox]="iconViewBox"></app-svg-icon>
    <div *ngIf="label">{{ label }}</div>
    <div *ngIf="showSubmenu" class="dropdown__submenu e-submenu" #submenu>
        <div *ngFor="let section of sections" [ngClass]="'e-submenu-section-' + section.type">
            <ng-container *ngFor="let element of section.elements">
                <div *ngIf="element.type === 'divider' && element.enabled !== false" class="submenu-divider"></div>

                <div
                    *ngIf="!element.type && element.enabled !== false"
                    class="e-submenu-row"
                    [ngClass]="computeSubmenuClass(element)"
                    (click)="onSubmenuClick($event, element)"
                >
                    <app-svg-icon
                        *ngIf="element.icon"
                        [path]="element.icon"
                        [viewBox]="element.iconViewBox ?? '-10 0 1044 1024'"
                    ></app-svg-icon>

                    <div class="width-100">{{element.label}}</div>

                    <app-svg-icon
                        *ngIf="element.iconRight"
                        [path]="element.iconRight"
                        [viewBox]="element.iconRightViewBox ?? '-10 0 1044 1024'"
                    ></app-svg-icon>
                </div>
            </ng-container>
        </div>
    </div>
</button>
