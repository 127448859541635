import { Component, Input } from '@angular/core'
import { IconsUI } from '../core/icons-ui'
import { Icon, IconCustomProperty } from '../core/icons'
import { Util } from '../core/util/util'
import { Tags } from '../core/tags'
import { Properties } from '../core/properties'

@Component({
    selector: 'app-sidebar-multiple',
    templateUrl: './sidebar-multiple.component.html',
    styleUrls: ['./sidebar-multiple.component.scss']
})
export class SidebarMultipleComponent {
    @Input() core: IconsUI = new IconsUI()
    categorySpinner = false

    // --

    applyCommonTags(tagsString: string) {
        const tags = Tags.split(tagsString)
        const icons = this.core.selectedIconsDelta.icons

        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const i in icons) {
            const icon = icons[i]

            this.core.selectedIcons[i].tags = Util.unique(icon.tags.concat(tags))
        }
    }

    removeTag(tag: string) {
        for (const icon of this.core.selectedIcons) {
            const tags = Tags.splitLegacyTags(icon)
            const index = tags.indexOf(tag)

            if (index !== -1) {
                tags.splice(index, 1)
                icon.tags = tags
            }
        }
    }

    // --

    computeIconThumbStyle(icon: Icon) {
        return {
            'background-image': `url(${icon?.thumb})`
        }
    }

    computeNames() {
        return this.core.selectedIcons.map(icon => icon.name).join(', ')
    }

    computeCommonTags() {
        const appearances: Record<string, number> = {}
        const icons = this.core.selectedIconsDelta.icons

        for (const icon of icons) {
            const tags = Tags.splitLegacyTags(icon)

            for (const tag of Util.unique(tags)) {
                appearances[tag] = (appearances[tag] || 0) + 1
            }
        }

        const count = icons.length
        const commonTags = []

        for (const tag in appearances) {
            if (count === appearances[tag]) {
                commonTags.push(tag)
            }
        }

        return commonTags.join(', ')
    }

    computeAllTags() {
        const tagsAll: Set<string> = new Set()
        const icons = this.core.selectedIcons

        for (const icon of icons) {
            const tags = Tags.splitLegacyTags(icon)

            for (const tag of tags) {
                tagsAll.add(tag)
            }
        }
        return Array.from(tagsAll)
    }

    // --

    async onCommonTagsInputChange(event: Event) {
        if (event.target instanceof HTMLTextAreaElement) {
            const value = event.target.value

            this.applyCommonTags(value)
            await this.core.saveDebounced()
        }
    }

    async onRemoveTag(tag: string) {
        this.removeTag(tag)
        // TODO: common tags must be updated too?
        await this.core.saveDebounced()
    }

    async onIconsDelete(event: Event) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        await this.core.deleteIconsWithConfirm(this.core.selectedIcons)
    }

    onCustomPropertyChange(property: IconCustomProperty) {
        if (property instanceof Event) {
            return
        } // TODO: why

        const newValues = Util.unique(Tags.split(property.value))
        const resultOrigin = Properties.findProperty(this.core.selectedIconsDelta.propertiesModified, property.path)
        let removedValues: string[] = []

        if (resultOrigin) {
            const a1 = Tags.split(resultOrigin.property.value)
            const a2 = Tags.split(property.value)

            removedValues = Util.diff(a1, a2)
        }

        // TODO: resolve eslint exception
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const i in this.core.selectedIconsDelta.icons) {
            const icon = this.core.selectedIconsDelta.icons[i]
            const icon2 = this.core.selectedIcons[i]
            const result = Properties.findProperty(icon.properties ?? [], property.path)
            const result2 = Properties.findProperty(icon2.properties ?? [], property.path)

            if (result && result2) {
                let values = Tags.split(result.property.value)

                values = Util.removeElements(values, removedValues)
                values = values.concat(newValues)

                result2.property.value = Util.unique(values).join(', ')
            }
        }
    }

    onCustomPropertyAdd() {
        this.core.updateSelectedIconsDelta()
    }

    onCustomPropertyDelete() {
        this.core.updateSelectedIconsDelta()
    }

    async onUpdateCategories() {
        this.categorySpinner = true
        await this.core.updateCategories(this.core.selectedIcons)
        this.categorySpinner = false
    }
}
