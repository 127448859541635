import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { IconsUI } from '../core/icons-ui'
import { Error } from '../core/error'

type ModalLoginView = 'login' | 'registration' | 'reset-password'

@Component({
    selector: 'app-modal-login',
    templateUrl: './modal-login.component.html',
    styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent {
    @Input() core: IconsUI = new IconsUI()
    @ViewChild('firstName') firstNameRef?: ElementRef<HTMLInputElement>
    @ViewChild('lastName') lastNameRef?: ElementRef<HTMLInputElement>
    @ViewChild('email') emailRef?: ElementRef<HTMLInputElement>
    @ViewChild('password') passwordRef?: ElementRef<HTMLInputElement>
    @ViewChild('passwordRepeat') passwordRepeatRef?: ElementRef<HTMLInputElement>

    view: ModalLoginView = 'login'
    confirmationEmailStatus?: 'show' | 'success' | 'failure'
    resetPasswordSuccess = false
    infoMessage?: string
    errorMessage?: string
    loading = false

    computeTitle() {
        const titles: Record<ModalLoginView, string> = {
            login: $localize`:@@login-modal.login:Login`,
            registration: $localize`:@@login-modal.registration:Registration`,
            'reset-password': $localize`:@@login-modal.reset-password:Reset password`
        }

        return titles[this.view] ?? ''
    }

    // --

    async onLoginSubmit(event: SubmitEvent) {
        const email = this.emailRef?.nativeElement.value.trim() ?? ''
        const password = this.passwordRef?.nativeElement.value.trim() ?? ''

        event.preventDefault()

        console.log(`on submit: "${email}" "${password}"`)

        this.infoMessage = undefined
        this.errorMessage = undefined
        this.confirmationEmailStatus = undefined

        if (email.length > 0 && password.length > 0 && !this.loading) {
            this.loading = true

            const response = await this.core.api?.login(email, password)

            if (response) {
                if (response.success) {
                    // TODO: purge local storage (if user changed)

                    // purge session
                    this.core.setSession(undefined)

                    await this.core.updateSessionByState(response)

                    // close modal
                    this.core.loginModal = false
                } else {
                    if (response.error === 'User is not activated.') {
                        this.confirmationEmailStatus = 'show'
                    }
                    this.errorMessage = Error.getMessage(response.error, false)
                }
            } else {
                this.errorMessage = Error.DEFAULT_MESSAGE
            }

            this.loading = false
        }
    }

    async onRegistrationSubmit(event: SubmitEvent) {
        const firstName = this.firstNameRef?.nativeElement.value.trim() ?? ''
        const lastName = this.lastNameRef?.nativeElement.value.trim() ?? ''
        const email = this.emailRef?.nativeElement.value.trim() ?? ''
        const password = this.passwordRef?.nativeElement.value.trim() ?? ''
        const passwordRepeat = this.passwordRepeatRef?.nativeElement.value.trim() ?? ''

        event.preventDefault()

        console.log(`on submit: "${firstName}" "${lastName}" "${email}" "${password}" "${passwordRepeat}"`)
        // TODO: validate

        this.loading = true
        this.errorMessage = undefined

        const response = await this.core.api?.register({
            firstName,
            lastName,
            email,
            password,
            passwordRepeat
        })

        console.log('register', response)

        if (response) {
            if (response.success) {
                this.infoMessage = 'Registration was successful'

                // change to "login" view, so the user can log in
                this.view = 'login'
            } else {
                this.errorMessage = Error.DEFAULT_MESSAGE
            }
        } else {
            this.errorMessage = Error.DEFAULT_MESSAGE
        }

        this.loading = false
    }

    async onSendConfirmationEmail(event: MouseEvent) {
        event.preventDefault()

        const email = this.emailRef?.nativeElement.value.trim() ?? ''
        const response = await this.core.api?.userConfirmEmail(email)

        this.confirmationEmailStatus = response?.success === true ? 'success' : 'failure'
    }

    async onResetPasswordSubmit(event: SubmitEvent) {
        const email = this.emailRef?.nativeElement.value.trim() ?? ''

        event.preventDefault()

        // TODO: set semaphore to bock concurrent requests

        console.log(`on submit: "${email}"`)
        // TODO: validate

        this.loading = true

        this.infoMessage = undefined
        this.errorMessage = undefined
        this.resetPasswordSuccess = false

        const response = await this.core.api?.resetUserPassword(email)

        console.log('reset password', response)

        if (response) {
            if (response.success) {
                this.resetPasswordSuccess = true
            } else {
                this.errorMessage = Error.DEFAULT_MESSAGE
            }
        } else {
            this.errorMessage = Error.DEFAULT_MESSAGE
        }

        this.loading = false
    }

    onShowRegistration() {
        this.infoMessage = undefined
        this.errorMessage = undefined
        this.resetPasswordSuccess = false
        this.view = 'registration'
    }

    onShowResetPassword(event: MouseEvent) {
        event.preventDefault()
        this.infoMessage = undefined
        this.errorMessage = undefined
        this.resetPasswordSuccess = false
        this.view = 'reset-password'
    }

    onShowLogin() {
        this.infoMessage = undefined
        this.errorMessage = undefined
        this.resetPasswordSuccess = false
        this.view = 'login'
    }

    onClose() {
        this.infoMessage = undefined
        this.errorMessage = undefined
        this.resetPasswordSuccess = false
        this.view = 'login'
        this.core.loginModal = false
    }
}
