import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { IconsUI } from '../core/icons-ui'
import { UserUpdateOptions } from '../core/icons-api'

@Component({
    selector: 'app-modal-user',
    templateUrl: './modal-settings.component.html',
    styleUrls: ['./modal-settings.component.scss']
})
export class ModalSettingsComponent {
    @Input() core: IconsUI = new IconsUI()
    @ViewChild('firstName') firstNameRef?: ElementRef<HTMLInputElement>
    @ViewChild('lastName') lastNameRef?: ElementRef<HTMLInputElement>
    @ViewChild('password') passwordRef?: ElementRef<HTMLInputElement>
    @ViewChild('passwordRepeat') passwordRepeatRef?: ElementRef<HTMLInputElement>

    changePassword = false
    errorMessage?: string

    onChangePassword() {
        this.changePassword = true
    }

    async onSubmit(event: SubmitEvent) {
        const firstName = this.firstNameRef?.nativeElement.value.trim() ?? ''
        const lastName = this.lastNameRef?.nativeElement.value.trim() ?? ''
        // const email = this.emailRef?.nativeElement.value.trim() ?? ""

        event.preventDefault()

        // console.log(`on submit: "${firstName}" "${lastName}" "${password}" "${passwordRepeat}"`)

        if (!this.core.session?.access_token) {
            return
        }

        this.errorMessage = undefined

        // TODO: validate

        const u = this.core.session.user
        const updateData: UserUpdateOptions = {
            accessToken: this.core.session.access_token
        }

        if (u.first_name !== firstName) {
            updateData.firstName = firstName
        }
        if (u.last_name !== lastName) {
            updateData.lastName = lastName
        }

        if (this.changePassword) {
            const password = this.passwordRef?.nativeElement.value.trim() ?? ''
            const passwordRepeat = this.passwordRepeatRef?.nativeElement.value.trim() ?? ''

            if (password) {
                updateData.password = password
                updateData.passwordRepeat = passwordRepeat
            }
        }

        console.log('update data', updateData)

        // const response = null
        const response = await this.core.api?.updateUser(updateData)

        console.log('user-modal', response)

        if (response && response.success) {
            await this.core.updateSessionByState(response.state)

            // close modal
            this.core.userModal = false
        } else {
            this.errorMessage = $localize`:@@error.default:Something went wrong`
        }
    }

    onClose() {
        this.errorMessage = undefined
        this.changePassword = false
        this.core.userModal = false
    }
}
