import { Notification } from './notification'

export class Clipboard {
    public static copy(text: string) {
        return new Promise(resolve => {
            // direct browser support
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text).then(
                    () => resolve(true),
                    () => resolve(false)
                )
            }
            // fallback
            else if (document.execCommand) {
                const el = document.createElement('input')
                el.value = text
                document.body.append(el)

                el.select()
                el.setSelectionRange(0, text.length)

                const result = document.execCommand('copy')
                el.remove()

                resolve(result)
            }
            // no browser support
            else {
                resolve(false)
            }
        })
    }

    public static async copyWithNotification(text: string) {
        await Clipboard.copy(text)

        Notification.show($localize`:@@clipboard.copied:Copied to clipboard`)
    }
}
