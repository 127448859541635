import { Icon } from './icons'

export class Tags {
    public static split(tags: string) {
        return tags
            .split(',')
            .map(tag => tag.trim())
            .filter(tag => tag !== '')
    }

    public static splitLegacyTags(icon: Icon) {
        let tags: string[] = []
        for (const tag of icon.tags) {
            tags = tags.concat(Tags.split(tag))
        }

        return tags
    }
}
