export class Error {
    public static readonly DEFAULT_MESSAGE = $localize`:@@error.default:Something went wrong`

    private static readonly errorMessages: Record<string, string> = {
        'User is not activated.': $localize`:@@error.user-not-activated:Account is not activated`,
        'Bad email or password.': $localize`:@@error.bad-email-password:E-Mail or password is incorrect`,
        'Something went wrong.': $localize`:@@error.default:Something went wrong`
    }

    public static getMessage(serverMessage: string, defaultMessageAsFallback = true) {
        const defaultMessage = defaultMessageAsFallback ? Error.DEFAULT_MESSAGE : serverMessage
        return Error.errorMessages[serverMessage] ?? defaultMessage
    }
}
