import { Component, Inject, Input, LOCALE_ID } from '@angular/core'
import { IconsUI } from '../core/icons-ui'
import { SubmenuElement, SubmenuSection } from '../dropdown/dropdown.component'
import { I18n } from '../i18n.module'

@Component({
    selector: 'app-header-main',
    templateUrl: './header-main.component.html',
    styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent {
    @Input() core: IconsUI = new IconsUI()
    menuOptions: SubmenuSection[] = []

    constructor(@Inject(LOCALE_ID) public locale: string) {}

    // --

    onSearchInput(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            const query = event.target.value.toLowerCase()

            this.core.filterIcons({
                query: query
            })
        }
    }

    onHelp() {
        this.core.selectedIcons = []
        this.core.infoMode = true
    }

    onOpenLoginModal() {
        this.core.loginModal = true
    }

    onBeforeShowMenuDropdown() {
        const session = this.core.session
        const sessionExists = !!session

        this.menuOptions = [
            {
                type: 'grey',
                elements: [
                    // session exists. user is logged in. display user profile
                    {
                        enabled: sessionExists,
                        label: `${session?.user?.first_name} ${session?.user?.last_name}`,
                        readonly: true
                    }
                ]
            },
            {
                type: 'white',
                elements: [
                    {
                        enabled: sessionExists,
                        label: $localize`:@@header.refresh:Refresh`,
                        value: 'refresh',
                        clickedAsync: this.onRefresh.bind(this)
                    },
                    {
                        enabled: sessionExists,
                        active: session?.dirty,
                        readonly: !session?.dirty,
                        label: $localize`:@@header.save:Save`,
                        value: 'save',
                        clickedAsync: this.onSave.bind(this)
                    },
                    {
                        enabled: sessionExists,
                        label: $localize`:@@header.settings:Settings`,
                        value: 'settings',
                        clicked: this.onSettings.bind(this)
                    },
                    { enabled: sessionExists, type: 'divider' },
                    {
                        label: $localize`:@@header.api:API Documentation`,
                        value: 'api',
                        clicked: this.openApiModal.bind(this)
                    },
                    { type: 'divider' },
                    {
                        label: $localize`:@@locale.de:Deutsch`,
                        value: 'de',
                        clicked: this.onLocaleChange.bind(this),
                        iconRight:
                            this.locale === 'de'
                                ? 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'
                                : undefined,
                        iconRightViewBox: '0 0 24 24'
                    },
                    {
                        label: $localize`:@@locale.en:English`,
                        value: 'en',
                        clicked: this.onLocaleChange.bind(this),
                        iconRight:
                            this.locale === 'en'
                                ? 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'
                                : undefined,
                        iconRightViewBox: '0 0 24 24'
                    },
                    { type: 'divider' },
                    {
                        enabled: sessionExists,
                        label: $localize`:@@header.pricing:Pricing`,
                        clicked: this.onPricing.bind(this)
                    },
                    {
                        enabled: sessionExists,
                        label: $localize`:@@header.logout:Logout`,
                        clicked: this.onLogout.bind(this)
                    }
                ]
            }
        ]
    }

    onPricing() {
        this.core.purchaseModal.next(true)
    }

    async onLogout() {
        await this.core.logout()
    }

    onLocaleChange(element: SubmenuElement) {
        if (element.value) {
            // store the user's preferred locale in localstorage
            I18n.setLocaleToLocalStorage(element.value)

            // reload the page to apply the new locale
            location.reload()
        }
    }

    async onApply() {
        await this.core.createIconfont(this.core.icons)
    }

    async onRefresh() {
        await this.core.refreshFromServer()
    }

    async onSave() {
        await this.core.saveOnServer()
    }

    onSettings() {
        this.core.userModal = true
    }

    openApiModal() {
        this.core.apiModal = true
    }
}
