import { Icon, IconCustomProperty } from './icons'
import { Properties } from './properties'

interface IconMapping {
    name: string
    code: string
    meta?: any
}

export interface MappingParseData {
    name: Record<string, any>
    code: Record<string, any>
}

export interface MappingDiffData {
    names: Record<string, string>
    codes: Record<string, string>
    meta: Record<string, any>
}

export class Mapping {
    public static parse(icons: any): MappingParseData {
        const mappingName: Record<string, { name: string; code: string; meta?: unknown }> = {}
        const mappingCode: Record<string, { name: string; code: string; meta?: unknown }> = {}
        const iconKeys = Object.keys(icons)

        for (const iconName of iconKeys) {
            const icon = icons[iconName]
            let iconObject: IconMapping = icon

            // TODO: sv-metadata json support

            // value is a string
            if (typeof icon === 'string') {
                iconObject = {
                    name: iconName,
                    code: icon
                }
            }
            // value is metadata
            else if (!iconObject.name) {
                iconObject = {
                    name: iconName,
                    code: '',
                    meta: icon
                }
            }
            // compatible mapping
            else {
                const iconClone = Object.assign({}, icon)

                delete iconClone.code
                delete iconClone.name

                if (Object.keys(iconClone).length > 0) {
                    iconObject.meta = iconClone
                }
            }

            mappingName[iconObject.name] = iconObject
            mappingCode[iconObject.code] = iconObject
        }

        return {
            name: mappingName,
            code: mappingCode
        }
    }

    public static diff(icons: Icon[], parsed: any): MappingDiffData {
        const nameDiffs: Record<string, string> = {}
        const codeDiffs: Record<string, string> = {}
        const metaDiff: Record<string, any> = {}

        const mappingName = parsed.name
        const mappingCode = parsed.code

        for (const icon of icons) {
            const iconDataByName = mappingName[icon.name] || {}
            const iconDataByCode = mappingCode[icon.code] || {}

            if (iconDataByName.code && icon.code !== iconDataByName.code) {
                console.log('[code change]:', icon.name, '->', icon.code, '|', iconDataByName.code)
                codeDiffs[icon.code] = (mappingName[icon.name] || {}).code
            }

            if (iconDataByCode.name && icon.name !== iconDataByCode.name) {
                console.log('[name change]:', icon.code, '->', icon.name, '|', iconDataByCode.name)
                nameDiffs[icon.name] = (mappingCode[icon.code] || {}).name
            }

            if (iconDataByName.meta) {
                metaDiff[icon.name] = iconDataByName.meta
            }

            // TODO: compare content by name
            // TODO: compare content by code
        }

        return {
            names: nameDiffs,
            codes: codeDiffs,
            meta: metaDiff
        }
    }

    public static createDownloadMapping(icons: Icon[]) {
        const result = []

        for (const icon of icons) {
            let resultIcon: any = {
                name: icon.name,
                code: icon.code,
                tags: icon.tags
            }

            if (icon.properties) {
                resultIcon = { ...resultIcon, ...Mapping.createDownloadMappingProperties(icon.properties) }
            }
            result.push(resultIcon)
        }

        return result
    }

    // TODO: rename properly
    private static createDownloadMappingProperties(properties: IconCustomProperty[]) {
        const resultProperties: any = {}
        let noneEmpty = false

        for (const property of properties) {
            if (property.properties) {
                const props = Mapping.createDownloadMappingProperties(property.properties)

                if (props || property.value !== '') {
                    resultProperties[property.name] = {
                        value: property.value,
                        ...props
                    }
                    noneEmpty = true
                }
            } else if (property.value !== '') {
                resultProperties[property.name] = property.value
                noneEmpty = true
            }
        }

        return noneEmpty ? resultProperties : undefined
    }

    public static applyMetaData(icons: Icon[], metaDiffs: Record<string, any>) {
        for (const icon of icons) {
            const metaNew = metaDiffs[icon.name] || {}

            if (Object.keys(metaNew).length > 0) {
                let properties: IconCustomProperty[] = []

                // console.log("[UPDATE] ", icon.name, "|", metaNew)

                // move non-default properties into "properties"
                const props: Record<string, any> = {}
                for (const name in metaNew) {
                    if (name !== 'tags' && name !== 'hide') {
                        props[name] = metaNew[name]
                        delete metaNew[name]
                    }
                }

                metaNew.properties = { ...metaNew.properties, ...props }

                // build custom-properties objects
                if (metaNew.properties) {
                    properties = Mapping.buildPropertiesFromData(metaNew.properties)
                    delete metaNew.properties
                }

                // merge all default properties into icon
                Object.assign(icon, metaNew)

                if (properties.length > 0) {
                    if (!icon.properties) {
                        icon.properties = []
                    }
                    Mapping.applyProperties(icon.properties, properties)
                }
            } else {
                console.log('[NOTHING]', icon.code, icon.name, '|', metaNew)
            }
        }
    }

    private static buildPropertiesFromData(propertiesData: Record<string, string | { value?: string }>) {
        const properties: IconCustomProperty[] = []

        for (const name in propertiesData) {
            const property = propertiesData[name]

            if (typeof property === 'string') {
                properties.push({
                    name: name,
                    value: property,
                    path: Properties.buildPath('', name)
                })
            } else if (typeof property.value === 'string') {
                const value = property.value
                delete property.value

                properties.push({
                    name: name,
                    value: value,
                    path: Properties.buildPath('', name),
                    properties: this.buildPropertiesFromData(property)
                })
            }
        }

        return properties
    }

    private static applyProperties(properties: IconCustomProperty[], propertiesMeta: any) {
        for (const propertyMeta of propertiesMeta) {
            let existingProperty = null
            // TODO: resolve eslint exception
            // eslint-disable-next-line @typescript-eslint/no-for-in-array
            for (const n in properties) {
                if (propertyMeta.name === n || properties[n].name) {
                    existingProperty = properties[n]
                    break
                }
            }

            // prevent duplicates
            if (existingProperty === null) {
                console.log('property does not exist. push new')
                properties.push(propertyMeta)
            } else {
                console.log('property does exist. merge...')

                if (typeof propertyMeta.value !== 'undefined') {
                    existingProperty.value = propertyMeta.value
                }

                if (propertyMeta.properties) {
                    if (!existingProperty.properties) {
                        existingProperty.properties = []
                    }

                    if (typeof propertyMeta.properties !== 'undefined') {
                        Mapping.applyProperties(existingProperty.properties, propertyMeta.properties)
                    }
                }
            }
        }
    }
}
