import { Icon } from '../icons'
import { Util } from '../util/util'
import { BlobUtil } from '../util/blob'
import { Properties } from '../properties'
import { StorageIcon } from './icons-storage'

export class StorageUtil {
    public static toStorageIcons(icons: Icon[]): StorageIcon[] {
        const storageIcons: any = JSON.parse(JSON.stringify(icons)) // deep clone

        for (const icon of storageIcons) {
            delete icon.file // do not store the blob in localstorage
        }
        return storageIcons
    }

    public static toIcons(storageIcons: StorageIcon[]): Icon[] {
        const icons: Icon[] = []

        for (const storageIcon of storageIcons) {
            const properties = storageIcon.properties
                ? Properties.repair(storageIcon.properties)
                : storageIcon.properties

            // console.log(storageIcon)
            icons.push({
                ...storageIcon,
                properties: properties,
                name: Util.sanitizeName(storageIcon.name),
                file: BlobUtil.svgToBlob(storageIcon.content)
            })
        }
        return icons
    }
}
