import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { IconsAPI, RandomIconResponse } from '../core/icons-api'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Component({
    selector: 'app-icon-training',
    templateUrl: './icon-training.component.html',
    styleUrls: ['./icon-training.component.scss']
})
export class IconTrainingComponent implements OnInit {
    private readonly api: IconsAPI
    public response?: RandomIconResponse
    public loading = false

    constructor(private http: HttpClient) {
        this.api = new IconsAPI(this.http)
    }

    async ngOnInit() {
        await this.requestRandomIcon()
    }

    async requestRandomIcon() {
        this.loading = true
        this.response = undefined

        const response = await this.api.randomIcon()
        if (response) {
            this.response = response
        }
        this.loading = false
    }

    async onConfirm() {
        if (this.response) {
            const category = this.response.category
            const feedbackKey = this.response.key
            const accessToken = environment.apiToken

            const response = await this.api.randomIconFeedback('categories', feedbackKey, category, accessToken)
            console.log(response)

            await this.requestRandomIcon()
        }
    }

    async onNext() {
        await this.requestRandomIcon()
    }
}
