export interface StorageInitOptions {
    name?: string
}

export abstract class AbstractStorage {
    public abstract init(options?: StorageInitOptions): void
    public abstract get(key: string): Promise<string | null>
    public abstract set(key: string, value: unknown): Promise<void>
    public abstract delete(key: string): Promise<void>
    public abstract keys(): Promise<string[]>
}
