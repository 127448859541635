import { Component, Input } from '@angular/core'
import { IconsUI } from '../core/icons-ui'
import { Clipboard } from '../core/clipboard'
import { Util } from '../core/util/util'

type ApiEndpoint =
    | 'create-iconfont'
    | 'create-icons'
    | 'extract-icons'
    | 'download-icons-png'
    | 'user-update'
    | 'project-update'
    | 'state'

interface ApiEndpointData {
    name: ApiEndpoint
    label: string
    description: string
    code: string
}

const API_ENDPOINTS: Record<ApiEndpoint, ApiEndpointData> = {
    'create-iconfont': {
        name: 'create-iconfont',
        label: $localize`:@@api-modal.create.iconfont.title:Create Iconfont`,
        description: $localize`:@@api-modal.create.iconfont.params:List of 'Icon' elements, containing the 'code', 'name', the content of the SVG file ('content'), and optionally a list of 'tags'.`,
        code: Util.buildApiEndpointCode({
            name: 'create-iconfont',
            jsonData: `{"icons": [{"code": "0001", "content": "<?xml version=\\"1.0\\" standalone=\\"no\\"?> <!DOCTYPE svg PUBLIC \\"-//W3C//DTD SVG 1.1//EN\\" \\"http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\\"><svg xmlns=\\"http://www.w3.org/2000/svg\\" xmlns:xlink=\\"http://www.w3.org/1999/xlink\\" version=\\"1.1\\" viewBox=\\"-10 0 20 1024\\"><path fill=\\"currentColor\\" d=\\"M0 960v0v0v0z\\" /></svg>", "name": "uni0001", "tags": []}]}`
        })
    },
    'create-icons': {
        name: 'create-icons',
        label: $localize`:@@api-modal.create.icons.title:Create Icons`,
        description: '',
        code: Util.buildApiEndpointCode({
            name: 'create-icons',
            jsonData: '...'
        })
    },
    'extract-icons': {
        name: 'extract-icons',
        label: $localize`:@@api-modal.extract.icons.title:Extract Icons From TTF/EOT`,
        description: '',
        code: Util.buildApiEndpointCode({
            name: 'create-icons',
            jsonData: '...'
        })
    },
    'download-icons-png': {
        name: 'download-icons-png',
        label: 'Download PNG Icons',
        description: '',
        code: Util.buildApiEndpointCode({
            name: 'download-icons-png',
            jsonData: '...'
        })
    },
    'user-update': {
        name: 'user-update',
        label: 'Update User',
        description: '',
        code: Util.buildApiEndpointCode({
            name: 'user-update',
            jsonData: '...'
        })
    },
    'project-update': {
        name: 'project-update',
        label: 'Update Project',
        description: '',
        code: Util.buildApiEndpointCode({
            name: 'project-update',
            jsonData: '...'
        })
    },
    state: {
        name: 'state',
        label: 'State',
        description: '',
        code: Util.buildApiEndpointCode({
            name: 'state',
            jsonData: '...'
        })
    }
}

@Component({
    selector: 'app-modal-api-documentation',
    templateUrl: './modal-api-documentation.component.html',
    styleUrls: ['./modal-api-documentation.component.scss']
})
export class ModalApiDocumentationComponent {
    @Input() core: IconsUI = new IconsUI()

    selectedEndpoint: ApiEndpointData = API_ENDPOINTS['create-iconfont']
    apiEndpoints = API_ENDPOINTS

    computeEndpointClass(apiEndpointName: ApiEndpoint) {
        return {
            active: this.selectedEndpoint.name === apiEndpointName
        }
    }

    onSelectEndpoint(apiEndpointName: ApiEndpoint) {
        this.selectedEndpoint = API_ENDPOINTS[apiEndpointName]
    }

    async onCodeCopy() {
        await Clipboard.copyWithNotification(this.selectedEndpoint.code)
    }

    onClose() {
        this.core.apiModal = false
    }
}
