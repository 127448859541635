import { Component, Input } from '@angular/core'
import { IconsUI } from '../core/icons-ui'

@Component({
    selector: 'app-sidebar-overview',
    templateUrl: './sidebar-overview.component.html',
    styleUrls: ['./sidebar-overview.component.scss']
})
export class SidebarOverviewComponent {
    @Input() core: IconsUI = new IconsUI()
    mode: 'structure' | 'structure-values' = 'structure-values'

    categoriesExists() {
        return Object.keys(this.core.propertySchema).length > 0
    }

    computeToggleLabel(mode: 'structure' | 'structure-values') {
        return mode === 'structure'
            ? $localize`:@@sidebar.structure-with-values:Display structure with values`
            : $localize`:@@sidebar.structure-only:Display structure only`
    }

    onToggleMode() {
        this.mode = this.mode === 'structure' ? 'structure-values' : 'structure'
    }
}
