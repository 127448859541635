import { AbstractStorage, StorageInitOptions } from '../abstract-storage'

export class LocalStorage extends AbstractStorage {
    public override init(options: StorageInitOptions = {}) {}

    public override async get(key: string): Promise<string | null> {
        return new Promise(resolve => {
            const value = localStorage.getItem(key)
            resolve(value)
        })
    }

    public override async set(key: string, valueOrNull: unknown): Promise<void> {
        return new Promise(resolve => {
            try {
                const value = typeof valueOrNull === 'string' ? valueOrNull : JSON.stringify(valueOrNull)
                localStorage.setItem(key, value)
            } catch (e: any) {
                // TODO: QuotaExceededError possible
                console.warn('save', e.message)
            }
            resolve()
        })
    }

    public override async delete(key: string): Promise<void> {
        return new Promise(resolve => {
            const sessionJson = localStorage.getItem('session')
            let session

            try {
                if (sessionJson) {
                    session = JSON.parse(sessionJson)
                }
            } catch (error) {
                /* empty */
            }

            resolve(session || undefined)
        })
    }

    public override async keys(): Promise<string[]> {
        const blacklist = ['length', 'clear', 'getItem', 'key', 'removeItem', 'setItem']
        const keys: string[] = []

        for (const key in window.localStorage) {
            if (!blacklist.includes(key)) {
                keys.push(key)
            }
        }

        return new Promise(resolve => {
            resolve(keys)
        })
    }
}
