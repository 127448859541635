import { Icon } from '../icons'

export class VirtualList {
    private rows: Icon[][] = []
    private currentRow: Icon[] = []
    private readonly columnCount: number

    constructor(columnCount: number) {
        this.columnCount = columnCount
    }

    public push(icon: Icon) {
        // column is full
        if (this.currentRow.length + 1 > this.columnCount) {
            this.currentRow = []
        }

        // add new column to rows
        if (this.currentRow.length === 0) {
            this.rows.push(this.currentRow)
        }

        // push icon into current row
        this.currentRow.push(icon)
    }

    public getRows() {
        return this.rows
    }
}
