export interface PromptData {
    title: string
    text: string
    confirmLabel?: string
    cancelLabel?: string
    confirm: () => void
    cancel: () => void
}

export interface PromptConfirmOptions {
    title: string
    text: string
    confirmLabel?: string
    cancelLabel?: string
}

export class Prompt {
    public static data?: PromptData

    public static confirm(data: PromptConfirmOptions) {
        return new Promise<boolean>(resolve => {
            this.data = {
                ...data,
                confirm: () => resolve(true),
                cancel: () => resolve(false)
            }
        })
    }
}
