import { AbstractStorage, StorageInitOptions } from '../abstract-storage'
import * as localforage from 'localforage'

export class IndexedDb extends AbstractStorage {
    public override init(options: StorageInitOptions = {}) {
        localforage.config({
            name: options.name ?? 'default-storage',
            version: 1.0
        })
    }

    public override async get(key: string): Promise<string | null> {
        return (await localforage.getItem(key)) as string
    }

    public override async set(key: string, valueOrNull: unknown): Promise<void> {
        try {
            const value = typeof valueOrNull === 'string' ? valueOrNull : JSON.stringify(valueOrNull)
            await localforage.setItem(key, value)
        } catch (e: any) {
            console.warn('save', e.message)
        }
    }

    public override async delete(key: string): Promise<void> {
        await localforage.removeItem(key)
    }

    public override async keys(): Promise<string[]> {
        return localforage.keys()
    }
}
