<div
  class="icons-overview__icon noselect"
  [attr.data-index]="core.icons.indexOf(icon)"
  [ngClass]="{
    active: (core.selectedIcons.includes(icon)),
    duplicate: core.isDuplicate(icon),
    dragover: dragover,
    'no-glyph': !icon.glyph
  }"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave()"
  (drop)="onDrop($event)"
>
  <div
    class="icons-overview__icon-thumb"
    [ngStyle]="{'background-image': 'url(' + icon.thumb + ')'}"
  ></div>
  <div class="icons-overview__icon-details">
    <div class="icons-overview__icon-code text-size-10 ellipsis" [title]="icon.code">{{icon.code}}</div>
    <div class="icons-overview__icon-name text-size-10 ellipsis" [title]="icon.name">{{icon.name}}</div>
  </div>
  <button class="icons-overview__icon-delete-btn" (click)="onDelete($event)">
    <app-svg-icon
      path="M155 72l-73 73l367 367l-367 367l73 73l367 -367l367 367l73 -73l-367 -367l367 -367l-73 -73l-367 367z"
      viewBox="-10 0 1044 1024"
    ></app-svg-icon>
  </button>
</div>
