<app-modal *ngIf="core.loginModal" [title]="computeTitle()" (close)="onClose()">
    <!-- login -->
    <div *ngIf="view === 'login'">
        <form id="login-form" (submit)="onLoginSubmit($event)">
            <div class="m-vb-2">
                <label class="m-vb-1 semi-bold block" i18n="@@user.email">E-Mail</label>
                <input
                    type="text"
                    class="input-b"
                    i18n-placeholder="@@user.email.ph"
                    placeholder="Enter E-Mail"
                    #email>
            </div>
            <div class="m-vb-3">
                <div class="clearfix">
                    <label class="m-vb-1 semi-bold block float-left" i18n="@@user.password">Password</label>
                    <a href="#" class="float-right" (click)="onShowResetPassword($event)"
                       i18n="@@login-modal.reset-password">
                        Reset password
                    </a>
                </div>
                <input
                    type="password"
                    class="input-b"
                    i18n-placeholder="@@user.password.ph"
                    placeholder="Enter password"
                    #password>
            </div>

            <!-- TODO: "keep login" checkbox -->

            <!-- info message -->
            <div class="m-vb-3 text-center" *ngIf="infoMessage">{{ infoMessage }}</div>

            <!-- error message -->
            <div *ngIf="errorMessage && confirmationEmailStatus !== 'success'" class="error-message text-center m-vb-2">
                {{ errorMessage }}
            </div>

            <!-- confirmation email -->
            <div class="m-vb-3 text-center" *ngIf="confirmationEmailStatus">
                <a href="#" *ngIf="confirmationEmailStatus !== 'success'" (click)="onSendConfirmationEmail($event)"
                   i18n="@@login-modal.send-email">
                    Send Confirmation E-Mail
                </a>
                <div *ngIf="confirmationEmailStatus === 'success'" i18n="@@login-modal.send-email.success">
                    Confirmation E-Mail has been sent
                </div>
                <div *ngIf="confirmationEmailStatus === 'failure'" class="m-vt-2 color-error"
                     i18n="@@login-modal.send-email.fail">
                    Failed so send confirmation E-Mail
                </div>
            </div>

            <div class="m-vb-2 text-center">
                <div class="m-vb-2">
                    <button *ngIf="!loading" type="submit" class="m-h-auto display-flex" i18n="@@login-modal.login">
                        Login
                    </button>
                    <app-loading *ngIf="loading"></app-loading>
                </div>
                <a href="#" (click)="onShowRegistration()" i18n="@@login-modal.registration">Registration</a>
            </div>
        </form>
    </div>

    <!-- registration -->
    <div *ngIf="view === 'registration'">
        <!-- TODO: create registration-module -->
        <form id="registration-form" autocomplete="off" (submit)="onRegistrationSubmit($event)">
            <div class="m-vb-2">
                <label class="m-vb-1 semi-bold block" i18n="@@user.firstname">Firstname</label>
                <input
                    type="text"
                    class="input-b"
                    i18n-placeholder="@@user.firstname.ph"
                    placeholder="Enter firstname"
                    #firstName>
            </div>
            <div class="m-vb-2">
                <label class="m-vb-1 semi-bold block" i18n="@@user.lastname">Lastname</label>
                <input
                    type="text"
                    class="input-b"
                    i18n-placeholder="@@user.lastname.ph"
                    placeholder="Enter lastname"
                    #lastName>
            </div>
            <div class="m-vb-2">
                <label class="m-vb-1 semi-bold block" i18n="@@user.email">E-Mail</label>
                <input
                    type="text"
                    class="input-b"
                    i18n-placeholder="@@user.email.ph"
                    placeholder="Enter E-Mail"
                    #email>
            </div>
            <div class="m-vb-2">
                <label class="m-vb-1 semi-bold block" i18n="@@user.password">Password</label>
                <input
                    type="password"
                    class="input-b"
                    i18n-placeholder="@@user.password.ph"
                    placeholder="Enter password"
                    #password>
            </div>
            <div class="m-vb-3">
                <label class="m-vb-1 semi-bold block" i18n="@@user.password-repeat">Repeat password</label>
                <input
                    type="password"
                    class="input-b"
                    i18n-placeholder="@@user.password-repeat.ph"
                    placeholder="Repeat password"
                    #passwordRepeat>
            </div>

            <div *ngIf="errorMessage" class="error-message text-center m-vb-2">{{ errorMessage }}</div>

            <div class="m-vb-2 text-center">
                <div class="m-vb-2">
                    <button *ngIf="!loading" type="submit" class="m-h-auto display-flex" i18n="@@login-modal.register">
                        Register
                    </button>
                    <app-loading *ngIf="loading"></app-loading>
                </div>
                <a href="#" (click)="onShowLogin()" i18n="@@login-modal.login">Login</a>
            </div>
        </form>
    </div>

    <!-- reset-password -->
    <div *ngIf="view === 'reset-password'">
        <form id="reset-password-form" autocomplete="off" (submit)="onResetPasswordSubmit($event)">
            <div class="m-vb-2">
                <label class="m-vb-1 semi-bold block" i18n="@@user.email">E-Mail</label>
                <input
                    type="text"
                    class="input-b"
                    i18n-placeholder="@@user.email.ph"
                    placeholder="Enter E-Mail"
                    #email>
            </div>

            <div *ngIf="errorMessage" class="error-message text-center m-vb-2">{{ errorMessage }}</div>

            <div *ngIf="resetPasswordSuccess" class="text-center m-vb-2" i18n="@@login-modal.reset-password.success">
                Password has ben successfully reset
            </div>

            <div class="m-vb-2 text-center">
                <div class="m-vb-2">
                    <button
                        *ngIf="!loading && !resetPasswordSuccess"
                        type="submit"
                        class="m-h-auto display-flex"
                        i18n="@@login-modal.reset-password"
                    >
                        Reset password
                    </button>
                    <app-loading *ngIf="loading"></app-loading>
                </div>
                <a href="#" (click)="onShowLogin()" i18n="@@login-modal.login">Login</a>
            </div>
        </form>
    </div>
</app-modal>
