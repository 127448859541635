<div class="sidebar noselect text-left">
    <app-info *ngIf="core.infoMode"></app-info>
    <ng-template [ngIf]="!core.infoMode">
        <div class="buttons text-right">
            <button *ngIf="categoriesExists()" class="btn-t-b b-transparent" (click)="onToggleMode()">
                {{ computeToggleLabel(mode) }}
            </button>
        </div>
        <div class="sidebar-inner" [ngClass]="mode">
            <div class="semi-bold m-vb-2" i18n="@@sidebar.property-tree.title">Properties</div>
            <div *ngIf="!categoriesExists()" i18n="@@sidebar.property-tree.empty">No Properties</div>
            <div *ngFor="let o of core.propertySchema | keyvalue">
                <app-sidebar-overview-element
                    [element]="o.value"
                    [core]="core"
                ></app-sidebar-overview-element>
            </div>
        </div>
    </ng-template>
</div>
