import { Component } from '@angular/core'
import { IconsAPI, PredictIconsResponse, PredictResponse } from '../core/icons-api'
import { HttpClient } from '@angular/common/http'
import { BlobUtil } from '../core/util/blob'
import { environment } from '../../environments/environment'

@Component({
    selector: 'app-ai',
    templateUrl: './ai.component.html',
    styleUrls: ['./ai.component.scss']
})
export class AiComponent {
    private readonly api: IconsAPI
    public response?: PredictResponse | PredictIconsResponse
    public imageBase64?: string
    dragover = false

    constructor(private http: HttpClient) {
        this.api = new IconsAPI(this.http)
    }

    // --

    async processFile(file: File) {
        let response

        this.imageBase64 = await BlobUtil.blobToBase64(file)

        if (file.name.endsWith('.svg')) {
            response = await this.api.predict('categories', [file], environment.apiToken)
        } else {
            response = await this.api.predictIcons('categories', [file], environment.apiToken)
        }

        console.log(response)

        if (response) {
            this.response = response
        }
    }

    // --

    async onFileInput(event: Event) {
        if (event.target instanceof HTMLInputElement && event.target.files) {
            await this.processFile(event.target.files[0])
        }
    }

    onDragOver(event: Event) {
        event.preventDefault()
        event.stopPropagation()
        this.dragover = true
    }

    onDragLeave() {
        this.dragover = false
    }

    async onDrop(event: DragEvent) {
        event.preventDefault()

        this.dragover = false

        if (event.dataTransfer) {
            // TODO:
            // only svg icons are supported
            // if (file.type === 'image/svg+xml') {
            await this.processFile(event.dataTransfer.files[0])
            // }
        }
    }
}
