import { Icon, IconCustomProperty, IconProject, Icons } from './icons'
import { Mapping, MappingDiffData } from './mapping'
import { FontType, IconsAPI, StateResponse } from './icons-api'
import { Util } from './util/util'
import { BlobUtil } from './util/blob'
import { Prompt } from './prompt'
import { PropertySchemaElement } from './properties'
import { IconsStorage } from './storage/icons-storage'
import { StorageUtil } from './storage/util'
import { VirtualList } from './ui/virtual-list'
import { DownloadUtil } from './util/download'
import { BehaviorSubject } from 'rxjs'
import { Notification } from './notification'
import { DebouncedFunc } from 'lodash-es'
import * as _ from 'lodash'

export type SortProperty = 'name' | 'code'

export type IconClipboard = Omit<Icon, 'code' | 'file' | 'thumb' | 'glyph'>

export const EMPTY_ICON: Icon = {
    file: new Blob(),
    content: '',
    name: '',
    code: '',
    glyph: '',
    tags: [],
    thumb: '',
    category: ''
}

export interface IconsSession {
    access_token: string
    refresh_token?: string
    user: {
        id: number
        email: string
        first_name: string
        last_name: string
    }
    projects: IconProject[]
    dirty: boolean
}

interface IconsFilter {
    query: string
    properties: Record<string, string[]>
}

export class IconsUI extends Icons {
    public session?: IconsSession
    public sortProperty: SortProperty = 'code'
    public delta?: MappingDiffData
    public loading = false
    public loginModal = false
    public userModal = false
    public apiModal = false
    public loginExpiredModal = false
    public purchaseModal = new BehaviorSubject(false)
    public api?: IconsAPI
    public selectedProperty?: IconCustomProperty
    public draggedSchemaElement?: PropertySchemaElement
    public draggedSchemaElementParent?: PropertySchemaElement
    public iconRows: Icon[][] = []
    public iconColumnsCount: number
    public debugMode = false
    public infoMode = false
    public filter: IconsFilter = {
        query: '',
        properties: {}
    }
    public categories: string[] = []
    public shapes: string[] = []
    private static instance: IconsUI
    public saveDebounced: DebouncedFunc<() => Promise<void>>

    constructor(api?: IconsAPI) {
        super()
        if (api) {
            this.api = api
        }

        this.saveDebounced = _.debounce(this.save, 1000)
        this.iconColumnsCount = this.determineIconColumnsCount()
        this.debugMode = this.getDebugMode() === 'true'
    }

    public static initInstance(api?: IconsAPI) {
        if (!IconsUI.instance) {
            IconsUI.instance = new IconsUI(api)
        }
        return IconsUI.instance
    }

    public static getInstance() {
        return IconsUI.instance
    }

    public override addIcon(icon: Icon) {
        super.addIcon(icon)
        this.updateIconRows()
    }

    public override removeIcon(icon: Icon) {
        super.removeIcon(icon)
        this.updateIconRows()
    }

    public override setIcons(icons: Icon[]) {
        super.setIcons(icons)
        this.updateIconRows()
    }

    public override reset() {
        super.reset()
        this.updateIconRows()
    }

    public sortIcons(sortProperty: SortProperty = this.sortProperty) {
        this.sortProperty = sortProperty

        this.icons.sort((iconA: Icon, iconB: Icon) => {
            const propertyA = iconA[sortProperty]
            const propertyB = iconB[sortProperty]

            return propertyA.localeCompare(propertyB)
        })

        this.updateIconRows()
    }

    public filterIcons(filter: Partial<IconsFilter> = {}) {
        this.filter = {
            ...this.filter,
            ...filter
        }

        const query = this.filter.query
        const virtualList = new VirtualList(this.iconColumnsCount)

        for (const icon of this.icons) {
            let foundQuery =
                icon.name.toLowerCase().includes(query) ||
                icon.code.toLowerCase().includes(query) ||
                (icon.category ?? '').toLowerCase().includes(query) ||
                (icon.categoryAlternative ?? '').toLowerCase().includes(query) ||
                (icon.shape ?? '').toLowerCase().includes(query) ||
                (icon.shapeAlternative ?? '').toLowerCase().includes(query) ||
                (icon.glyph ?? '').toLowerCase().includes(query)
            let foundCPValue = undefined
            let displayIcon: boolean

            for (const tag of icon.tags) {
                if (tag.toLowerCase().includes(query)) {
                    foundQuery = true
                    break
                }
            }

            if (icon.properties) {
                const propertiesStack = [icon.properties]

                do {
                    const properties = propertiesStack.pop() ?? []

                    for (const property of properties) {
                        const filterValues = this.filter.properties[property.path]
                        if (filterValues) {
                            foundCPValue = filterValues.includes(property.value)
                        }

                        if (property.value.toLowerCase().includes(query)) {
                            foundQuery = true
                        }

                        if (property.properties && property.properties.length > 0) {
                            propertiesStack.push(property.properties)
                        }
                    }
                } while (propertiesStack.length > 0 && foundCPValue !== false)
            }

            /**
             * custom-properties-filter has a higher priority, than the query-filter.
             * if a custom property is filtered, we can additionally filter this set with the query-filter to a smaller subset
             */
            if (typeof foundCPValue !== 'undefined') {
                if (foundCPValue) {
                    displayIcon = foundQuery
                } else {
                    displayIcon = false
                }
            } else {
                displayIcon = foundQuery
            }

            if (displayIcon) {
                virtualList.push(icon)
            }
        }

        this.iconRows = virtualList.getRows()
    }

    public updateIconRows() {
        this.filterIcons()
    }

    public determineIconColumnsCount() {
        return Math.floor((window.innerWidth - 300) / 150)
    }

    public async deleteIconsWithConfirm(icons: Icon[]) {
        let title, text, confirmLabel, cancelLabel

        if (icons.length === 1) {
            title = $localize`:@@delete-icon-modal.single.title:Delete Icon`
            text = $localize`:@@delete-icon-modal.single.text:Are you sure you want to delete the icon?`
            confirmLabel = $localize`:@@delete-icon-modal.single.confirm:Yes, delete`
            cancelLabel = $localize`:@@delete-icon-modal.single.cancel:Cancel`
        } else {
            title = $localize`:@@delete-icon-modal.multiple.title:Delete Icons`
            text = $localize`:@@delete-icon-modal.multiple.text:Are you sure you want to delete the selected icon?`
            confirmLabel = $localize`:@@delete-icon-modal.multiple.confirm:Yes, delete selection`
            cancelLabel = $localize`:@@delete-icon-modal.multiple.cancel:Cancel`
        }

        const confirmed = await Prompt.confirm({
            title: title,
            text: text,
            confirmLabel: confirmLabel,
            cancelLabel: cancelLabel
        })

        if (confirmed) {
            for (const icon of icons) {
                this.removeIcon(icon)
            }

            await this.save()
            this.infoMode = false
            // reset selection, to close the right side-menu, because these icons do not exist anymore
            this.resetSelection()
        }
    }

    public async resetWithConfirm() {
        const confirmed = await Prompt.confirm({
            title: $localize`:@@reset-modal.title:Reset`,
            text: $localize`:@@reset-modal.text:Are you sure you want to discard and reset everything?`,
            confirmLabel: $localize`:@@reset-modal.confirm:Yes, discard`,
            cancelLabel: $localize`:@@reset-modal.cancel:Cancel`
        })

        if (confirmed) {
            this.reset()
            await this.save()
        }
    }

    public async replaceIconContentWithConfirm(icon: Icon, file: Blob) {
        const confirmed = await Prompt.confirm({
            title: $localize`:@@replace-content-modal.title:Replace content`,
            text: $localize`:@@replace-content-modal.text:Are you sure you want to replace the content of the icon?`,
            confirmLabel: $localize`:@@replace-content-modal.confirm:Yes, replace`,
            cancelLabel: $localize`:@@replace-content-modal.cancel:Cancel`
        })

        if (confirmed) {
            await this.replaceIconContent(icon, file)
            this.updateDuplicates()
            await this.save()
        }
    }

    // TODO: move to separate file/processing.ts -- start
    public async processFiles(files: any) {
        const unsupportedFileExtensionsMap: Record<string, boolean> = {}

        // TODO: process ttf and eot files inside loop

        console.log(files[0].type)

        // extract woff
        if (files.length === 1 && (files[0].type === 'application/font-woff' || files[0].name.endsWith('.woff'))) {
            await this.processFontFile(files[0], 'woff')
        }
        // extract woff2
        // else if (files.length === 1 && files[0].name.endsWith('.woff2')) {
        //     await this.processFontFile(files[0], 'woff2')
        // }
        // extract ttf
        else if (files.length === 1 && (files[0].type === 'font/ttf' || files[0].name.endsWith('.ttf'))) {
            await this.processFontFile(files[0], 'ttf')
        }
        // extract eot
        else if (
            (files.length === 1 && files[0].type === 'application/vnd.ms-fontobject') ||
            files[0].name.endsWith('.eot')
        ) {
            await this.processFontFile(files[0], 'eot')
        }
        // svg or json files
        else if (files.length > 0) {
            for (const file of files) {
                if (['image/svg+xml'].includes(file.type)) {
                    await this.processSvgFile(file)
                } else if (['application/json'].includes(file.type)) {
                    // TODO: try/catch
                    await this.processMappingFile(file)
                    // TODO: store mapping in parallel to map new icons properly
                } else {
                    const fileExtension = this.getExtension(file.name)
                    console.log('unsupported file type:', file.type, fileExtension)
                    unsupportedFileExtensionsMap[fileExtension] = true
                }
            }
        }

        const unsupportedFileExtensions = Object.keys(unsupportedFileExtensionsMap)
        if (unsupportedFileExtensions.length > 0) {
            Notification.showError(`${$localize`:@@error.unsupported-types:`}: ${unsupportedFileExtensions.join(', ')}`)
        }

        await this.save()
    }

    private getExtension(filename: string) {
        return filename.split('.').pop() ?? ''
    }

    private async processFontFile(file: File, type: FontType) {
        console.log('loading...')

        this.loading = true

        const icons = await this.api?.extractFont(file, type)

        if (icons) {
            for (const icon of icons) {
                this.addIcon(icon)
            }
            await this.save()
        } else {
            Notification.showError()
        }

        this.loading = false
    }

    private async processMappingFile(file: File) {
        const content = await BlobUtil.blobToText(file)
        const icons = JSON.parse(content)
        const parsed = Mapping.parse(icons)

        console.log('mapping json detected', icons)

        this.delta = Mapping.diff(this.icons, parsed)
    }

    private async processSvgFile(file: File) {
        const content = await BlobUtil.blobToText(file)
        const parser = new DOMParser()
        const document = parser.parseFromString(content, 'text/xml')
        const glyphNodes = Array.from(document.getElementsByTagName('glyph'))

        // TODO: handle general svg sprite with "defs" -> processSvgIconfontSprite(...)

        // handle svg sprite (from iconfont)
        if (glyphNodes.length > 0) {
            this.processIconfontSvgSprite(glyphNodes)
        }
        // handle single svg
        else {
            this.processSingleSvgFile(file, content)
        }
    }

    private processIconfontSvgSprite(glyphNodes: Element[]) {
        const fontFaceNode = document.getElementsByTagName('font-face')[0]
        let fontHeight = 0

        console.log('fontFaceNode', fontFaceNode)

        if (fontFaceNode) {
            const unitsPerEm = parseInt(fontFaceNode.getAttribute('units-per-em') ?? '0')

            if (unitsPerEm > 0) {
                fontHeight = unitsPerEm
                console.log('units-per-em', unitsPerEm)
            } else {
                const ascent = parseInt(fontFaceNode.getAttribute('ascent') ?? '0')
                const descent = parseInt(fontFaceNode.getAttribute('descent') ?? '0')

                fontHeight = ascent + descent
                console.log('ascent+descent', ascent, descent, fontHeight)
            }
        }

        for (const glyphNode of glyphNodes) {
            const iconSvgPath = glyphNode.getAttribute('d')

            if (iconSvgPath) {
                const iconWidth = parseInt(glyphNode.getAttribute('horiz-adv-x') ?? '0')
                const iconHeight = fontHeight > 0 ? fontHeight : iconWidth
                const iconSvgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="${iconWidth}" height="${iconHeight}" viewBox="0 0 ${iconWidth} ${iconHeight}">
                                    <path transform="scale(1 -1) translate(0 -${iconHeight})" d="${iconSvgPath}"/>
                                </svg>`
                const iconCode = glyphNode.getAttribute('unicode') || '' // TODO: generate next available code
                const iconName = glyphNode.getAttribute('glyph-name') || iconCode || ''
                const iconFile = BlobUtil.svgToBlob(iconSvgContent)

                this.addIcon({
                    file: iconFile,
                    content: iconSvgContent,
                    name: iconName,
                    code: iconCode,
                    glyph: '', // TODO:
                    tags: [],
                    thumb: ''
                })
            }
        }
    }

    private processSingleSvgFile(file: File, content: string) {
        const parsedName = Util.parseIconFileName(file.name)

        this.addIcon({
            file: file,
            content: content,
            name: parsedName.name,
            code: parsedName.code,
            glyph: '', // TODO:
            tags: [],
            thumb: ''
        })
    }
    // TODO: move to separate file/processing.ts -- end

    public async createIconfont(icons: Icon[], serveDownload = false) {
        const duplicates = this.detectDuplicates(icons)

        // redundant icon(s) exists
        if (duplicates.names.size > 0 || duplicates.codes.size > 0 || duplicates.contents.size > 0) {
            const confirmed = await Prompt.confirm({
                title: $localize`:@@create.font.duplicates-modal.title:`,
                text: $localize`:@@create.font.duplicates-modal.text:`,
                confirmLabel: $localize`:@@create.font.duplicates-modal.confirm:`
            })

            if (!confirmed) {
                return
            }
        }

        this.loading = true

        const result = await this.api?.createIconfont(icons)
        if (result) {
            // update glyphs only if all icons are selected -> subsets result in another font with possible other results
            if (icons.length === this.icons.length) {
                // update glyphs
                for (const icon of this.icons) {
                    if (result.glyphs[icon.name]) {
                        icon.glyph = result.glyphs[icon.name]
                    }
                }
                await this.save()
            }
            //  { name: 'uxxxx-.notdef', unicode: [ '' ] }

            if (serveDownload) {
                DownloadUtil.serveBlob(result.blob)
            }
        } else {
            Notification.showError()
        }

        this.loading = false
    }

    public async save(updateServer = true) {
        await IconsStorage.saveIcons(this.icons)

        if (this.session && updateServer) {
            this.session.dirty = true
            await this.saveOnServer()
        }
    }

    public async saveSession() {
        await IconsStorage.saveSession(this.session)
    }

    public async saveOnServer() {
        // TODO: stop here if there is a running query
        // TODO: debounce
        if (!this.session) {
            return
        }

        // save icons on the server (current project)
        const response = await this.api?.updateProject({
            id: this.session.projects[0].id,
            icons: this.icons,
            accessToken: this.session.access_token
        })

        console.log(response)

        // update version in session
        if (response && response.success) {
            this.session.dirty = false
            await this.saveSession()
            // this.session.projects[0].version = response.version
        } else {
            Notification.showError()
        }
    }

    public async refreshFromServer() {
        if (!this.session) {
            return
        }

        const response = await this.api?.state(this.session.access_token)

        console.log('refresh', response)
        if (response) {
            await this.updateSessionByState(response)
        } else {
            Notification.showError()
        }
    }

    public async updateSessionByState(state: StateResponse) {
        try {
            const project = state.projects[0]
            const iconsJson = project.icons

            this.setSession({
                dirty: false,
                access_token: state.access_token,
                refresh_token: state.refresh_token,
                user: state.user,
                projects: [
                    // TODO: support multiple projects
                    {
                        id: project.id
                        // version: project.version
                    }
                ]
            })
            await this.saveSession()

            if (iconsJson) {
                const storageIcons = JSON.parse(iconsJson)
                this.setIcons(StorageUtil.toIcons(storageIcons))
            }

            // this function will always be called after data has been fetched from server, so we do NOT need to update the data on the server
            await this.save(false)
        } catch (error) {
            console.warn('[session]', error)
        }
    }

    public setSession(session?: IconsSession) {
        // use refresh-token from current session
        if (session && !session.refresh_token && this.session) {
            session.refresh_token = this.session.refresh_token
        }

        this.session = session
    }

    public async logout() {
        this.setSession(undefined)
        await this.saveSession()

        // location.reload()
    }

    public async refreshToken() {
        if (this.session && this.session.refresh_token) {
            const response = await this.api?.refreshToken(this.session.refresh_token)

            if (response?.access_token) {
                this.session.access_token = response?.access_token
                await this.saveSession()

                console.log('token automatically refreshed', this.session.access_token)
                return true
            }
        }
        return false
    }

    async updateCategories(icons: Icon[]): Promise<boolean> {
        if (!this.session?.access_token) {
            return false
        }

        const chunkSize = 16

        // request icon categories chunk-wise
        for (let i = 0; i < icons.length; i += chunkSize) {
            const iconsSubarray = icons.slice(i, i + chunkSize)
            const response = await this.api?.predict('categories', iconsSubarray, this.session.access_token)

            if (response) {
                console.log(response.predictions, response.predictions_alt)

                // distribute categories on icons
                for (let j = 0; j < response.predictions.length; j++) {
                    iconsSubarray[j].category = response.predictions[j]
                    iconsSubarray[j].categoryAlternative = response.predictions_alt[j]
                }
                await this.save()
            } else {
                console.log(response)
            }
        }

        return true
    }

    async loadCategories() {
        if (this.categories.length === 0 && this.session?.access_token) {
            const response = await this.api?.categories(this.session.access_token)

            if (response?.categories) {
                this.categories = response.categories
            }
        }
    }

    async loadShapes() {
        if (this.shapes.length === 0 && this.session?.access_token) {
            const response = await this.api?.shapes(this.session.access_token)

            if (response?.shapes) {
                this.shapes = response.shapes
            }
        }
    }

    private getDebugMode() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        return urlParams.get('debug')
    }
}
