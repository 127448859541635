<div class="sidebar noselect">
    <div
        class="sidebar__icon-thumb cursor-pointer"
        [ngClass]="{ dragover: thumbDragover }"
        [ngStyle]="computeIconThumbStyle()"
        (dragover)="onThumbDragOver($event)"
        (dragleave)="onThumbDragLeave()"
        (drop)="onThumbDrop($event)"
    >
        <button class="replace-button btn-b-2" i18n="@@sidebar.replace">Replace</button>
        <input type="file" [attr.id]="uploadInputId" (change)="onFileReplace($event)" accept="image/svg+xml">
        <label class="cursor-pointer" [attr.for]="uploadInputId"></label>
    </div>
    <div class="sidebar__icon-details text-center">
        <div class="sidebar__icon-name ellipsis m-vb-1" [title]="computeIcon().name">{{computeIcon().name}}</div>
        <div class="sidebar__icon-code ellipsis" [title]="computeIcon().code">{{computeIcon().code}}</div>

        <div style="display: inline-flex; gap: 8px;" class="m-vt-1">
            <button class="btn-c m-vt-2 rounded-text" (click)="onDownloadSvg($event)">
                <app-svg-icon
                    path="M480 6v846l-286 -286l-61 61l359 358l30 29l30 -29l359 -358l-61 -61l-286 286v-846h-84z"
                    viewBox="-10 0 1044 1024"
                ></app-svg-icon>
                <div i18n="@@sidebar.download.svg">Download SVG</div>
            </button>
            <button class="btn-c m-vt-2 rounded-text" (click)="onDownloadPng($event)">
                <app-svg-icon
                    path="M480 6v846l-286 -286l-61 61l359 358l30 29l30 -29l359 -358l-61 -61l-286 286v-846h-84z"
                    viewBox="-10 0 1044 1024"
                ></app-svg-icon>
                <div i18n="@@sidebar.download.png">Download PNG</div>
            </button>
        </div>

        <div style="display: inline-flex; gap: 8px;" class="m-vt-1">
            <button class="rounded-text" (click)="onCopyName()">
                <app-svg-icon
                    path="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                    viewBox="0 0 24 24"
                ></app-svg-icon>
                <div i18n="@@sidebar.copy.name">Name</div>
            </button>
            <button class="rounded-text" (click)="onCopyCode()">
                <app-svg-icon
                    path="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                    viewBox="0 0 24 24"
                ></app-svg-icon>
                <div i18n="@@sidebar.copy.code">Code</div>
            </button>
            <button class="rounded-text" *ngIf="computeIcon().glyph" (click)="onCopyGlyph()">
                <app-svg-icon
                    path="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                    viewBox="0 0 24 24"
                ></app-svg-icon>
                <div i18n="@@sidebar.copy.glyph">Glyph</div>
            </button>
        </div>
    </div>
    <div class="m-vt-3 text-left">
        <label class="block m-vb-1 semi-bold" i18n="@@sidebar.name.label">Name</label>
        <input
            class="m-vb-2"
            placeholder="Enter name"
            i18n-placeholder="@@sidebar.name.placeholder"
            [value]="computeIcon().name"
            (input)="onInputChange($event, 'name')"
        />

        <label class="block m-vb-1 semi-bold" i18n="@@sidebar.code.label">Code</label>
        <input
            class="m-vb-2"
            placeholder="Enter code"
            i18n-placeholder="@@sidebar.code.placeholder"
            [value]="computeIcon().code"
            (input)="onInputChange($event, 'code')"
        />

        <!-- category -->
        <ng-template [ngIf]="isLoggedIn()">
            <label class="block m-vb-1 semi-bold" i18n="@@sidebar.category.label">Category</label>
            <div class="m-vb-1">
                <app-loading *ngIf="computeIcon().categoryLoading"></app-loading>
                <ng-template [ngIf]="!computeIcon().categoryLoading">
                    <div *ngIf="computeIcon().category" class="category-inner-wrapper">
                        <div style="display: flex;">
                            <div>{{ computeIcon().category }}</div>
                            <div style="display: flex; gap: 4px" *ngIf="computeIcon().categoryAlternative && computeIcon().category !== computeIcon().categoryAlternative">
                                {{ ',' }}
                                <div (click)="onAlternativeCategorySelect()" class="category-alt">
                                    {{ computeIcon().categoryAlternative }}
                                </div>
                            </div>
                        </div>
                        <a href="#" (click)="onCategoryRequest()">Update</a>
                    </div>
                    <button class="rounded-text" *ngIf="!computeIcon().category" (click)="onCategoryRequest()">Request Category</button>
                </ng-template>
            </div>

            <div class="ng-autocomplete m-vb-2">
                <ng-autocomplete
                    [data]="core.categories"
                    [initialValue]="computeIcon().category ?? '-'"
                    (selected)='onCategorySelect($event)'
                    (inputChanged)='onCategoryInput($event)'
                    (closed)='onCategoryClose()'
                    [itemTemplate]="itemTemplate"
                ></ng-autocomplete>

                <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item"></a>
                </ng-template>
            </div>
        </ng-template>

        <!-- shape -->
        <ng-template [ngIf]="isLoggedIn()">
            <label class="block m-vb-1 semi-bold" i18n="@@sidebar.shape.label">Shape</label>
            <div class="m-vb-1">
                <app-loading *ngIf="computeIcon().shapeLoading"></app-loading>
                <ng-template [ngIf]="!computeIcon().shapeLoading">
                    <div *ngIf="computeIcon().shape" class="category-inner-wrapper">
                        <div style="display: flex;">
                            <div>{{ computeIcon().shape }}</div>
                            <div style="display: flex; gap: 4px" *ngIf="computeIcon().shapeAlternative && computeIcon().shape !== computeIcon().shapeAlternative">
                                {{ ',' }}
                                <div (click)="onAlternativeShapeSelect()" class="category-alt">
                                    {{ computeIcon().shapeAlternative }}
                                </div>
                            </div>
                        </div>
                        <a href="#" (click)="onShapeRequest()">Update</a>
                    </div>
                    <button class="rounded-text" *ngIf="!computeIcon().shape" (click)="onShapeRequest()">Request Shape</button>
                </ng-template>
            </div>

            <div class="ng-autocomplete m-vb-2">
                <ng-autocomplete
                    [data]="core.shapes"
                    [initialValue]="computeIcon().shape ?? '-'"
                    (selected)='onShapeSelect($event)'
                    (inputChanged)='onShapeInput($event)'
                    (closed)='onShapeClose()'
                    [itemTemplate]="itemTemplate"
                ></ng-autocomplete>

                <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item"></a>
                </ng-template>
            </div>
        </ng-template>

        <label class="block m-vb-1 semi-bold" i18n="@@sidebar.tags.label">Tags</label>
        <textarea
            class="block m-vb-2 line-height-paragraph"
            placeholder="Enter tags"
            i18n-placeholder="@@sidebar.tags.placeholder"
            style="height: 68px;"
            [value]="computeTags()"
            (input)="onInputChange($event, 'tags')"
        ></textarea>

        <!-- custom properties -->
        <app-custom-property
            *ngFor="let property of computeProperties()"
            [property]="property"
            [core]="core"
            class="m-vb-2"
        ></app-custom-property>

        <button class="rounded-text" (click)="onAddCustomProperty()" i18n="@@sidebar.property.add">Add Property</button>

        <button class="rounded-text m-vt-3 m-vb-3 display-flex m-h-auto" (click)="onIconDelete($event)" i18n="@@sidebar.delete.icon">
            Delete icon
        </button>
    </div>
</div>
