import { Icon } from '../icons'
import { Storage } from './storage'
import { IconsSession } from '../icons-ui'
import { StorageUtil } from './util'

export type StorageIcon = Omit<Icon, 'file' | 'hide'>
export class IconsStorage extends Storage {
    public static async getIcons(): Promise<Icon[]> {
        const iconsJson = await IconsStorage.get('icons', true)
        let icons: Icon[] = []

        if (iconsJson) {
            const storageIcons: StorageIcon[] = JSON.parse(iconsJson) || []
            icons = StorageUtil.toIcons(storageIcons)
        }

        return new Promise(resolve => {
            resolve(icons)
        })
    }

    public static saveIcons(icons: Icon[]): Promise<void> {
        return IconsStorage.set('icons', icons)
    }

    public static async getSession(): Promise<IconsSession | undefined> {
        const sessionJson = await IconsStorage.get('session', true)

        return new Promise(resolve => {
            let session: any

            try {
                if (sessionJson) {
                    session = JSON.parse(sessionJson)
                }
            } catch (error) {
                /* empty */
            }
            resolve(session || undefined)
        })
    }

    public static saveSession(session?: IconsSession): Promise<void> {
        return IconsStorage.set('session', session)
    }
}
