import { environment } from '../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Icon } from './icons'
import { BlobUtil } from './util/blob'
import { StorageUtil } from './storage/util'

interface RegisterOptions {
    firstName: string
    lastName: string
    email: string
    password: string
    passwordRepeat: string
}

interface RegisterRequestData {
    first_name: string
    last_name: string
    email: string
    password: string
    password_repeat: string
}

interface RegisterResponse {
    success: boolean
}

interface LoginRequestData {
    email: string
    password: string
}

type LoginResponse =
    | (StateResponse & {
          success: true
          refresh_token: string
      })
    | LoginErrorResponse

interface LoginErrorResponse {
    success: false
    error: 'User is not activated.'
}

interface RefreshTokenRequestData {}

type RefreshTokenResponse = {
    success: boolean
    access_token: string
}

interface UserConfirmEmailRequestData {
    email: string
}

interface UserConfirmEmailResponse {
    success: boolean
}

interface UserResetPasswordRequestData {
    email: string
}

interface UserResetPasswordResponse {
    success: boolean
}

interface StateRequestData {}

export type FontType = 'ttf' | 'eot' | 'woff' /*| 'woff2'*/

export interface StateResponse {
    access_token: string
    refresh_token?: string
    user: User
    projects: Project[]
}

interface User {
    id: number
    email: string
    first_name: string
    last_name: string
}

interface Project {
    id: number
    // TODO: user_id, created_date, ...
    // version: number

    // TODO: should we parse icons it on the server?
    icons: string
}

interface ProjectUpdateOptions {
    id: number
    icons?: Icon[]
    accessToken: string
}

interface ProjectUpdateRequestData {
    id: number
    icons?: string
    // TODO: name
}

interface ProjectUpdateResponse {
    success: boolean
    // version: number
}

export interface UserUpdateOptions {
    firstName?: string
    lastName?: string
    password?: string
    passwordRepeat?: string
    accessToken: string
}

interface UserUpdateRequestData {
    first_name?: string
    last_name?: string
    password?: string
    password_repeat?: string
}

interface UserUpdateResponse {
    success: boolean
    state: StateResponse
}

export interface ProductsOptions {
    accessToken: string
}

interface ProductsRequestData {}

interface ProductsResponse {
    success: boolean
    products: Product[]
    paddle: {
        sandbox: boolean
        vendor_id: number
    }
}

export interface Product {
    product_id: number
    name: string
    description: string
    status: 'paid' | 'subscribed' | null
}

interface ExtractFontRequestData {
    buffer: string
}

interface ExtractFontResponse {
    icons: ResponseIcon[]
}

interface CreateIconfontRequestData {
    icons: RequestIcon[]
}

interface CreateIconfontResponse {
    glyphs: Record<string, string>
    buffer: {
        data: number[]
    }
}

interface CreateIconfontResponseData {
    blob: Blob
    glyphs: Record<string, string>
}

interface DownloadIconsRequestData {
    icons: RequestIcon[]
}

interface DownloadPngIconsRequestData {
    icons: RequestIcon[]
}

interface DownloadIconsResponse {
    buffer: {
        data: number[]
    }
}

interface DownloadPngIconsResponse {
    buffer: {
        data: number[]
    }
}

type PredictModelName = 'categories' | 'shapes'

interface PredictRequestData {
    'model-name': PredictModelName
    'svg-content-array': string[]
    names?: string[]
}

export interface PredictResponse {
    predictions: string[]
    predictions_alt: string[]
    previews?: string[][]
}
interface PredictIconsRequestData {
    'model-name': PredictModelName
    'base64-content-array': string[]
    names?: string[]
}

export interface PredictIconsResponse {
    predictions: string[]
    predictions_alt: string[]
    previews?: string[][]
}

interface FeedbackRequestData {
    'model-name': PredictModelName
    'svg-content': string
    'feedback-value': string
}

interface FeedbackResponse {
    success: boolean
}

interface CategoriesRequestData {}

interface CategoriesResponse {
    success: boolean
    categories: string[]
}

interface ShapesRequestData {}

interface ShapesResponse {
    success: boolean
    shapes: string[]
}

interface RandomIconRequestData {}

export interface RandomIconResponse {
    success: boolean
    data_url: string
    key: string
    category: string
    previews?: string[]
}

interface RandomIconFeedbackRequestData {
    'model-name': PredictModelName
    key: string
    'feedback-value': string
}

interface RandomIconFeedbackResponse {
    success: boolean
}

interface RequestIcon {
    name: string
    code: string
    tags: string[]
    content: string
}

interface ResponseIcon {
    svg: string
    name: string
    code: string
    glyph: string
}

export class IconsAPI {
    private http: HttpClient

    constructor(http: HttpClient) {
        this.http = http
    }

    // --

    public async register(options: RegisterOptions): Promise<RegisterResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.apiUrl}/register`
            const requestData: RegisterRequestData = {
                first_name: options.firstName,
                last_name: options.lastName,
                email: options.email,
                password: options.password,
                password_repeat: options.passwordRepeat
            }

            this.http.post<RegisterResponse>(requestUrl, requestData).subscribe({
                next: (response: RegisterResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async login(email: string, password: string): Promise<LoginResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.apiUrl}/login`
            const requestData: LoginRequestData = {
                email: email,
                password: password
            }

            this.http.post<LoginResponse>(requestUrl, requestData).subscribe({
                next: (response: LoginResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async refreshToken(refreshToken: string): Promise<RefreshTokenResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.apiUrl}/token-refresh`
            const requestData: RefreshTokenRequestData = {}
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${refreshToken}` })
            }

            this.http.post<RefreshTokenResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: RefreshTokenResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async userConfirmEmail(email: string): Promise<UserConfirmEmailResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.apiUrl}/user-confirm-email`
            const requestData: UserConfirmEmailRequestData = {
                email: email
            }

            this.http.post<UserConfirmEmailResponse>(requestUrl, requestData).subscribe({
                next: (response: UserConfirmEmailResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async resetUserPassword(email: string): Promise<UserResetPasswordResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.apiUrl}/user-reset-password`
            const requestData: UserResetPasswordRequestData = {
                email: email
            }

            this.http.post<UserResetPasswordResponse>(requestUrl, requestData).subscribe({
                next: (response: UserResetPasswordResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async state(accessToken: string): Promise<StateResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.apiUrl}/state`
            const requestData: StateRequestData = {}
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` })
            }

            this.http.post<StateResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: StateResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async updateProject(options: ProjectUpdateOptions): Promise<ProjectUpdateResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.apiUrl}/project-update`
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${options.accessToken}` })
            }
            const requestData: ProjectUpdateRequestData = {
                id: options.id
            }

            if (options.icons) {
                const storageIcons = StorageUtil.toStorageIcons(options.icons)
                requestData.icons = JSON.stringify(storageIcons)
            }

            this.http.post<ProjectUpdateResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: ProjectUpdateResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async updateUser(options: UserUpdateOptions): Promise<UserUpdateResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.apiUrl}/user-update`
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${options.accessToken}` })
            }
            const requestData: UserUpdateRequestData = {
                first_name: options.firstName,
                last_name: options.lastName,
                password: options.password,
                password_repeat: options.passwordRepeat
            }

            this.http.post<UserUpdateResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: UserUpdateResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async products(options: ProductsOptions): Promise<ProductsResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.apiUrl}/products`
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${options.accessToken}` })
            }
            const requestData: ProductsRequestData = {}

            this.http.post<ProductsResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: ProductsResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async createIconfont(icons: Icon[]): Promise<CreateIconfontResponseData | null> {
        return new Promise(resolve => {
            setTimeout(async () => {
                const requestUrl = `${environment.baseUrl}/create-iconfont`
                const requestData: CreateIconfontRequestData = {
                    icons: await this.buildRequestIcons(icons)
                }

                this.http.post<CreateIconfontResponse>(requestUrl, requestData).subscribe({
                    next: (response: CreateIconfontResponse) => {
                        // TODO: get glyphs from response grouped vy code ?!
                        const blob = BlobUtil.bufferToBlob(response.buffer.data as number[])

                        console.log('response', response)
                        resolve({ blob: blob, glyphs: response.glyphs })
                    },
                    error: error => {
                        console.warn(error)
                        resolve(null)
                    }
                })
            })
        })
    }

    public async downloadIcons(icons: Icon[]): Promise<Blob | null> {
        return new Promise(resolve => {
            setTimeout(async () => {
                const requestUrl = `${environment.baseUrl}/download-icons`
                const requestData: DownloadIconsRequestData = {
                    icons: await this.buildRequestIcons(icons)
                }

                this.http.post<DownloadIconsResponse>(requestUrl, requestData).subscribe({
                    next: (response: DownloadIconsResponse) => {
                        const blob = BlobUtil.bufferToBlob(response.buffer.data)

                        console.log('response', response)
                        resolve(blob)
                    },
                    error: error => {
                        console.warn(error)
                        resolve(null)
                    }
                })
            })
        })
    }

    public async downloadPngIcons(icons: Icon[]): Promise<Blob | null> {
        return new Promise(resolve => {
            setTimeout(async () => {
                const requestUrl = `${environment.baseUrl}/download-icons-png`
                const requestData: DownloadPngIconsRequestData = {
                    // TODO: zip or png (if single icon only)
                    icons: await this.buildRequestIcons(icons)
                }

                this.http.post<DownloadPngIconsResponse>(requestUrl, requestData).subscribe({
                    next: (response: DownloadIconsResponse) => {
                        const blob = BlobUtil.bufferToBlob(response.buffer.data)

                        console.log('response', response)
                        resolve(blob)
                    },
                    error: error => {
                        console.warn(error)
                        resolve(null)
                    }
                })
            })
        })
    }

    public async extractFont(file: Blob, type: FontType): Promise<Icon[] | null> {
        return new Promise(resolve => {
            setTimeout(async () => {
                const requestUrl = `${environment.baseUrl}/extract-${type}`
                const requestData: ExtractFontRequestData = {
                    buffer: await BlobUtil.blobToBase64(file)
                }

                this.http.post<ExtractFontResponse>(requestUrl, requestData).subscribe({
                    next: (response: ExtractFontResponse) => {
                        const icons: Icon[] = []

                        for (const responseIcon of response.icons) {
                            icons.push({
                                file: BlobUtil.svgToBlob(responseIcon.svg),
                                content: responseIcon.svg,
                                name: responseIcon.name.replace('.svg', ''),
                                code: responseIcon.code,
                                glyph: responseIcon.glyph,
                                tags: [],
                                thumb: ''
                            })
                        }
                        resolve(icons)
                    },
                    error: error => {
                        console.warn(error)
                        resolve(null)
                    }
                })
            })
        })
    }

    // TODO: remove accessToken
    public async predict(
        modelName: PredictModelName,
        icons: (File | Icon)[],
        accessToken: string
    ): Promise<PredictResponse | null> {
        const svgContents: string[] = []
        const names: string[] = []

        for (const icon of icons) {
            if (icon instanceof File) {
                svgContents.push(await BlobUtil.blobToText(icon))
            } else {
                svgContents.push(await BlobUtil.blobToText(icon.file))
            }
            names.push(icon.name)
        }

        return new Promise(resolve => {
            const requestUrl = `${environment.aiApiUrl}/predict`
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` })
            }
            const requestData: PredictRequestData = {
                'model-name': modelName,
                'svg-content-array': svgContents,
                names: names
            }

            this.http.post<PredictResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: PredictResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async predictIcons(
        modelName: PredictModelName,
        files: File[],
        accessToken: string
    ): Promise<PredictIconsResponse | null> {
        const base64Contents: string[] = []
        const names: string[] = []

        for (const file of Array.from(files)) {
            base64Contents.push(await BlobUtil.blobToBase64(file))
            names.push(file.name)
        }

        return new Promise(resolve => {
            const requestUrl = `${environment.aiApiUrl}/predict_icon`
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` })
            }
            const requestData: PredictIconsRequestData = {
                'model-name': modelName,
                'base64-content-array': base64Contents,
                names: names
            }

            this.http.post<PredictIconsResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: PredictIconsResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async feedback(
        modelName: PredictModelName,
        svgOrFile: Blob | string,
        feedbackValue: string,
        accessToken: string
    ): Promise<FeedbackResponse | null> {
        const svg = typeof svgOrFile === 'string' ? svgOrFile : await BlobUtil.blobToText(svgOrFile)

        return new Promise(resolve => {
            const requestUrl = `${environment.aiApiUrl}/feedback`
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` })
            }
            const requestData: FeedbackRequestData = {
                'model-name': modelName,
                'svg-content': svg,
                'feedback-value': feedbackValue
            }
            console.log('-> send', requestData)

            this.http.post<FeedbackResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: FeedbackResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async categories(accessToken: string): Promise<CategoriesResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.aiApiUrl}/categories`
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` })
            }
            const requestData: CategoriesRequestData = {}

            this.http.post<CategoriesResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: CategoriesResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async shapes(accessToken: string): Promise<ShapesResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.aiApiUrl}/shapes`
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` })
            }
            const requestData: ShapesRequestData = {}

            this.http.post<ShapesResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: ShapesResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async randomIcon(): Promise<RandomIconResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.aiApiUrl}/random_icon`
            const requestData: RandomIconRequestData = {}

            this.http.post<RandomIconResponse>(requestUrl, requestData).subscribe({
                next: (response: RandomIconResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    public async randomIconFeedback(
        modelName: PredictModelName,
        feedbackKey: string,
        feedbackValue: string,
        accessToken: string
    ): Promise<RandomIconFeedbackResponse | null> {
        return new Promise(resolve => {
            const requestUrl = `${environment.aiApiUrl}/feedback_random_icon`
            const requestOptions = {
                headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` })
            }
            const requestData: RandomIconFeedbackRequestData = {
                'model-name': modelName,
                key: feedbackKey,
                'feedback-value': feedbackValue
            }

            this.http.post<RandomIconFeedbackResponse>(requestUrl, requestData, requestOptions).subscribe({
                next: (response: RandomIconFeedbackResponse) => {
                    resolve(response)
                },
                error: error => {
                    console.warn(error)
                    resolve(null)
                }
            })
        })
    }

    // --

    private async buildRequestIcons(icons: Icon[]): Promise<RequestIcon[]> {
        const requestIcons: RequestIcon[] = []

        for (const icon of icons) {
            const svg = await BlobUtil.blobToText(icon.file)
            requestIcons.push({
                name: icon.name.replace('.svg', ''),
                code: icon.code,
                tags: icon.tags,
                content: svg
            })
        }
        return requestIcons
    }
}
