import { Injectable } from '@angular/core'
import { catchError, Observable, switchMap, throwError } from 'rxjs'
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http'
import { IconsUI } from './icons-ui'

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    tokenRefreshPending = false

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // console.log("interceptor: " + req.url);

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMsg = ''

                // client side error
                if (error.error instanceof ErrorEvent) {
                    console.warn('This is client side error')
                    errorMsg = `Error: ${error.error.message}`
                }
                // server side error
                else {
                    console.warn('This is server side error')
                    errorMsg = `Error Code: ${error.status},  Message: ${error.message}`

                    if (error.status === 401) {
                        if (this.tokenRefreshPending) {
                            this.autoLogout() // TODO:
                            return throwError('Session timeout: Logout automatically.')
                        }

                        return this.refreshAccessToken().pipe(
                            // retry request with new access-token
                            switchMap(() => {
                                const accessToken = IconsUI.getInstance().session?.access_token
                                const headers = new HttpHeaders({ Authorization: `Bearer ${accessToken}` })

                                req = req.clone({ headers })
                                return next.handle(req)
                            })
                        )
                    }
                }

                return throwError(errorMsg)
            })
        )
    }

    refreshAccessToken(): Observable<unknown> {
        return new Observable(observer => {
            setTimeout(async () => {
                let tokenRefreshed = false

                console.log('refresh access-token ( tokenRefreshPending =', this.tokenRefreshPending, ')')

                if (!this.tokenRefreshPending) {
                    this.tokenRefreshPending = true

                    const instance = IconsUI.getInstance()

                    if (instance.session?.refresh_token) {
                        tokenRefreshed = await instance.refreshToken()
                    }
                }

                // token successfully refreshed
                if (tokenRefreshed) {
                    observer.next()
                    observer.complete()
                    this.tokenRefreshPending = false
                    console.log('token successfully refreshed')
                }
                // token refresh failed
                else {
                    observer.error()
                    console.log('token refresh failed')
                }
            })
        })
    }

    async autoLogout() {
        const instance = IconsUI.getInstance()
        await instance.logout()
        instance.loginExpiredModal = true

        this.tokenRefreshPending = false
        console.log('logout automatically')
    }
}
