<div class="sidebar noselect">
    <div class="sidebar__icons">
        <div class="sidebar__icons-inner">
            <div *ngFor="let icon of core.selectedIcons" class="sidebar__icon-thumb"
                 [ngStyle]="computeIconThumbStyle(icon)"></div>
        </div>
    </div>
    <div class="sidebar__icon-details text-center">
        <div class="sidebar__icon-name ellipsis m-vb-1" [title]="computeNames()">{{computeNames()}}</div>
        <app-dropdown-download
            [core]="core"
            [icons]="core.selectedIcons"
            class="block m-vt-2"
        ></app-dropdown-download>
    </div>
    <div class="m-vt-3 text-left">
        <label class="block m-vb-1 semi-bold" i18n="@@sidebar.tags.label.common">Common Tags</label>
        <textarea
            class="block m-vb-2 line-height-paragraph"
            placeholder="Enter tags"
            i18n-placeholder="@@sidebar.tags.placeholder"
            style="height: 68px;"
            [value]="computeCommonTags()"
            (input)="onCommonTagsInputChange($event)"
        ></textarea>

        <label class="block m-vb-1 semi-bold" i18n="@@sidebar.tags.label.all">All tags</label>
        <div class="m-vb-2">
            <button *ngFor="let tag of computeAllTags()" style="margin: 0 4px 4px 0;" class="active rounded-text">
                <div>{{ tag }}</div>
                <app-svg-icon
                    path="M155 72l-73 73l367 367l-367 367l73 73l367 -367l367 367l73 -73l-367 -367l367 -367l-73 -73l-367 367z"
                    viewBox="-10 0 1044 1024"
                    (click)="onRemoveTag(tag)"
                ></app-svg-icon>
            </button>
        </div>

        <label class="block m-vb-2 semi-bold" i18n="@@sidebar.properties.label.common">Common properties</label>
        <div>
            <app-custom-property
                *ngFor="let property of core.selectedIconsDelta.properties"
                [property]="property"
                [core]="core"
                (change)="onCustomPropertyChange($event)"
                (add)="onCustomPropertyAdd()"
                (delete)="onCustomPropertyDelete()"
                class="m-vb-2"
            ></app-custom-property>
        </div>

        <div class="m-vt-3 m-vb-3">
            <div *ngIf="core.debugMode" class="m-vb-1 text-center">
                <app-loading *ngIf="categorySpinner"></app-loading>
                <button *ngIf="!categorySpinner" (click)="onUpdateCategories()">Update Categories</button>
            </div>

            <button class="rounded-text display-flex m-h-auto" (click)="onIconsDelete($event)" i18n="@@sidebar.delete.icons">
                Delete Icons
            </button>
        </div>
    </div>
</div>
