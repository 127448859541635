<div class="text-center">
    <h1 class="m-vb-3" i18n="@@training.title">AI Training</h1>

    <div class="content">
        <app-loading *ngIf="loading"></app-loading>

        <button
            *ngIf="!loading && !response"
            class="btn-c b-transparent retry-btn"
            (click)="onNext()"
            i18n="@@training.retry"
        >Try again</button>

        <div *ngIf="!loading && response">
            <div class="icon-container m-vb-3">
                <img [src]="response.data_url"/>
            </div>

            <h2 class="m-vb-3" i18n="@@training.text">Does the above image look like the images below?</h2>

            <div class="previews m-vb-3">
                <div *ngFor="let previewDataUrl of response.previews" class="preview">
                    <img [src]="previewDataUrl"/>
                </div>
            </div>

            <div class="buttons m-vb-3">
                <button (click)="onConfirm()" i18n="@@training.yes">Yes</button>
                <button (click)="onNext()" i18n="@@training.no">No</button>
                <button class="btn-t-b b-transparent" (click)="onNext()" i18n="@@training.next">Next</button>
            </div>
        </div>
    </div>
    <a class="m-vt-3 m-vb-3 block" href="/ai" i18n="@@training.try">Try it yourself</a>
</div>
